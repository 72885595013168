import http from "./HTTP";
import UserProfile from "./UserProfile";

class UserManager {
    constructor() {
        this.data = {};
        this.loadedUsers = null;
        this.data.users = new Map();
        this.data.userByName = new Map();
    }

    loadUsers() {
        if (this.loadedUsers) return this.loadedUsers;
        const restURI = "user/?active=true";
        this.loadedUsers = http
            .sendRestRequest(restURI)
            .then((result) => {
                result.forEach((element) => {
                    this.data.users.set("" + element.userId, element);
                    this.data.userByName.set(
                        "" + element.userEmail,
                        element.userId
                    );
                });

                return result;
            })
            .catch((error) => {
                console.error("Error loading users:", error);

                this.loadedUsers = null;
            });
        return this.loadedUsers; // Return the Promise immediately
    }
    getAllProfiles = () => {
        return this.data.users;
    };
    getProfileById(userId) {
        if (this.data.users.has(userId)) {
            return new UserProfile(this.data.users.get(userId));
        }
    }
    getProfileByName(userName) {
        if (this.data.userByName.has(userName)) {
            return new UserProfile(this.data.userByName.get(userName));
        }
    }
}
let userManager = new UserManager();
//userManager.loadUsers();
export default userManager;
