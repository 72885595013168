import React, { Component } from "react";
import http from "../../../../bae-client/HTTP";
import userManager from "../../../../bae-client/UserManager";
import AlertDialog from "../../../stylized/AlertDialog";
import ChipsView from "../../../stylized/ChipsView";
import Loader from "../../../stylized/Loader";
import ModalView from "../../../stylized/ModalView";
import TextArea from "../../../stylized/TextArea";
import TextBox from "../../../stylized/TextBox";
import { ValidateEmail } from "../../../../bae-client/Utils";
import { Mixpanel } from "../../../../mix-panel/Mixpanel";

class InviteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            value: "",
            chipsArray: [],
            invited: false,
            emailError: "",
            messageError: "",
            showAlertDialog: false,
            alertDialogTitle: "",
            alertDialogMessage: "",
            isAlert: false,
        };
        this.wholeContacts = null;
        this.selectedUsername = "";
        this.searchText = "";
        this.chipsArray = [];
        this.messageBody = "";
    }
    changeOpenDialogFlag = (flag) => {
        this.setState({ showAlertDialog: flag });
    };
    showAlertView = (title, body) => {
        this.setState({
            showAlertView: true,
            alertDialogMessage: body,
            alertDialogTitle: title,
        });
    };
    componentDidUpdate() {
        if(this.props.show){
            userManager.loadUsers().then((resp) => {
                let allContacts = userManager.getAllProfiles();
                this.wholeContacts = this.parseLoadedContact(allContacts);
            });
        }
    }
    parseLoadedContact = (contactRawList) => {
        let contactList = [];
        contactRawList.forEach((key, index) => {
            contactList.push({
                username: key.firstName + " " + key.lastName,
                userEmail: key.userEmail,
                userId: key.userId,
                company: "Not Specified",
                read: "false",
                chatCount: 0,
                profileImg: "A",
            });
        });
        return contactList;
    };
    clearData = () => {
        this.setState({ chipsArray: [], emailError: "" });
        this.chipsArray = [];
        this.searchText = "";
    };
    sendInvite = (emails) => {
        let message =
            this.messageBody !== ""
                ? this.messageBody
                : "Welcome to the Unity Central!";
        let url = "register/inviteUsers";
        let data = { emailAddresses: emails, message: message };

        this.setState({ showLoader: true });

        // Send invitation request
        http.sendRestRegistraionRequest(url, "POST", data)
            .then((response) => {
                // Track invitations for each email
                emails.forEach((personMailId) => {
                    Mixpanel.track("Sent Invitation", {
                        "Person Invited": personMailId,
                    });
                });

                // Update state after successful invitation
                this.setState({
                    showLoader: false,
                    invited: true,
                });

                this.clearData(); // Clear input data after successful invitation
            })
            .catch((err) => {
                this.showAlertView("Error", err.error); // Show error message
                this.setState({ showLoader: false }); // Hide loader on error
            });
    };

    handleSendInviteButtonClick = () => {
        let emails = this.getChipsText();
        if (this.searchText.trim() !== "") {
            let isChipCreated = this.createChip(this.searchText.trim());
            if (isChipCreated) {
                emails = this.getChipsText();
                // this.sendInvite(emails);
            }
        } else if (this.searchText === "" && emails.length) {
            emails = this.getChipsText();
            this.sendInvite(emails);
        } else if (!emails.length) {
            this.setState({ emailError: "Please add at least One email!" });
            return;
        }
    };
    handleSkipButton = () => {
        this.setState({ invited: false });
        this.props.hide();
    };
    closeModal = () => {
        this.setState({ invited: false });
    };
    closeInviteModal = () => {
        this.clearData();
        this.props.hide();
    };
    putTextInChips = (text) => {
        let index = 0;
        index = this.chipsArray.length;
        this.chipsArray.push({
            key: text + "_" + index,
            label: text,
        });
        this.setState({ chipsArray: this.chipsArray });
    };
    handleDeleteChipItem = (chipToDelete, index) => {
        let items = this.chipsArray;
        items.splice(index, 1);
        this.setState({ chipsArray: items });
    };
    setFromText = (value, id, event) => {
        //takes input values from textbox component
        switch (id) {
            case "inviteMailTextBox":
                {
                    this.searchText = value;
                }
                break;
            case "txt_Invite_messageBody": {
                this.messageBody = value;
                if (this.state.messageError && value)
                    this.setState({ messageError: "" });
            }
            default:
                break;
        }
    };
    getChipsText = () => {
        let str = [];
        this.chipsArray.forEach((element, index) => {
            str.push(element.label.toString());
        });
        return str;
    };
    isValidEmail = (mail) => {
        let email = mail.trim();
        if (email !== "") {
            let isValidEmail = ValidateEmail(email);
            if (isValidEmail) {
                return isValidEmail;
            } else {
                this.setState({
                    emailError: "Please Enter valid Email",
                });
                return isValidEmail;
            }
        } else {
            this.setState({
                emailError: "Please Enter Email",
            });
            return false;
        }
    };
    handleKeyDown = (value, key) => {
        if (value.trim() !== "") {
            this.createChip(value.trim());
        }
    };
    createChip = (value) => {
        let isValidEmail = this.isValidEmail(value.trim());
        if (!isValidEmail) {
            return false;
        }
        if (value !== "" && isValidEmail) {
            let flag = false;
            this.chipsArray.forEach((item) => {
                if (item.label === this.searchText) {
                    flag = true;
                }
            });
            if (flag === false) {
                this.putTextInChips(this.searchText);
                this.searchText = "";
                this.setState({ emailError: "" });
            }
            document.getElementById("inviteMailTextBox").value = "";
            return true;
        }
    };
    renderClassForChips = () => {
        if (this.chipsArray.length <= 3) {
            return "col-" + this.chipsArray.length * 3;
        } else {
            return "col-9";
        }
    };
    renderChipsInputUI = () => {
        return (
            <div className="w-100 m-0 doCenter" style={{ flexWrap: "wrap" }}>
                <div className="invite_div_box">
                    <div className="row w-100 m-0" style={{ display: "block" }}>
                        <ChipsView
                            items={this.state.chipsArray}
                            ondelete={this.handleDeleteChipItem}
                        ></ChipsView>

                        <TextBox
                            divClass={"rippleSerachDiv"}
                            className="userSerachInputBox invite-search"
                            id="inviteMailTextBox"
                            clearAfterChange={true}
                            type="text"
                            label={
                                this.state.chipsArray.length === 0
                                    ? "Enter e-mail"
                                    : "Enter another e-mail"
                            }
                            placeholder={true}
                            onkeydown={this.handleKeyDown}
                            handleSpecialKeys={this.handleKeyDown}
                            onchange={this.setFromText}
                        ></TextBox>
                    </div>
                </div>
                {this.state.emailError && (
                    <>
                        <small style={{ color: "red" }}>
                            {this.state.emailError}
                        </small>
                        <br />
                    </>
                )}
                <br />
            </div>
        );
    };

    renderTextArea = () => {
        if (this.chipsArray.length > 0) {
            return (
                <TextArea
                    divClass="w-100 doCenter"
                    className="invite_msg_box "
                    id="txt_Invite_messageBody"
                    rows="5"
                    cols="25"
                    label={"Write a custom message"}
                    onchange={this.setFromText}
                ></TextArea>
            );
        }
    };
    renderModalBody = () => {
        return (
            <div className="row doCenter" style={{ flexDirection: "column" }}>
                <div className="row m-0 doCenter mb-4">
                    <span className="invite_modal_title">
                        Invite to Unity Central
                    </span>
                </div>
                {this.renderChipsInputUI()}
                {this.renderTextArea()}
                {this.state.messageError && (
                    <>
                        <small style={{ color: "red" }}>
                            {this.state.messageError}
                        </small>
                        <br />
                    </>
                )}
                <br />

                <button
                    type="submit"
                    id="btn_invite_submit"
                    className="btn btn-primary submitBtn mt-3"
                    style={{ fontWeight: "400", maxWidth: "20rem" }}
                    name="btn_signup"
                    onClick={this.handleSendInviteButtonClick}
                    title="SEND INVITATION"
                >
                    SEND INVITATION
                </button>
            </div>
        );
    };

    renderModal = () => {
        return (
            <ModalView
                show={this.props.show}
                onHide={this.closeInviteModal}
                footer={"false"}
                size="md"
            >
                {this.renderModalBody()}
            </ModalView>
        );
    };
    renderAlertModel = () => {
        return (
            <ModalView
                show={this.state.invited}
                onHide={this.closeModal}
                footer={"false"}
                size="sm"
            >
                <div
                    className="w-100 doCenter"
                    style={{ flexDirection: "column" }}
                >
                    <div className="row m-0 w-100 doCenter">
                        <span className="modalTitle">Invite Sent</span>
                    </div>
                    <button
                        type="submit"
                        id="btn_invite_closeInviteSuccessModal"
                        className="btn btn-primary submitBtn"
                        style={{
                            fontWeight: "400",
                            maxWidth: "7rem",
                            height: "3rem",
                            marginTop: "2rem",
                        }}
                        onClick={this.handleSkipButton}
                    >
                        DONE
                    </button>
                </div>
            </ModalView>
        );
    };
    render() {
        return (
            <div>
                {this.renderModal()}
                {this.renderAlertModel()}
                <Loader show={this.state.showLoader} />
                <AlertDialog
                    open={this.state.showAlertDialog}
                    message={this.state.alertDialogMessage}
                    title={this.state.alertDialogTitle}
                    isAlert={this.state.isAlert}
                    setOpen={this.changeOpenDialogFlag}
                ></AlertDialog>
            </div>
        );
    }
}
export default InviteModal;
