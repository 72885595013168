import React, { useEffect, useState } from "react";
import CustomTable from "./CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import "./Workspaces.css";
import LeftActions from "./LeftActions";
import Breadcrumb from "./Breadcrumb";
import http from "../../../../../bae-client/HTTP";
import {
    setRefreshLocation,
    fetchDirectoriesAction,
    fetchDirectoryUsersAction,
    fetchSpaceListAction,
    setActiveWorkspaceAction,
    setEmailRecievedAction,
    setSnackbarAction,
    setSpaceListAction,
    setActivePageAction,
    setWorkspaceRedirectionAction,
} from "../../../../../services/services/app";
import Loader from "../../../../stylized/Loader";
import { columns } from "./constant";
import ModalView from "../../../../stylized/DocumentViewerModal";
import DocViwer from "../../../../stylized/DocViwer";
import RightActions from "./RightActions";
import ComposerModal from "../WorkspaceContainer/ComposerModal";
import moment from "moment";
import { useUsersQuery } from "../../../../../services/services/usersAPI";
import EmptyTable from "./EmptyTable";
import { selectVideoPlaylistVideoTrackByPeerID } from "@100mslive/react-sdk";

export const convertUTCToLocal = (date) => {
    const temp = date.split(".");
    const dateToConvert = temp[0];
    const localDate = moment
        .utc(dateToConvert)
        .local()
        .format("MM/DD/YYYY hh:mm A");
    return localDate;
};
const getOwnerName = (data, sharedBy) => {
    if (data && data.length) {
        const sharedByUser = data.find((user) => user.userId === sharedBy);
        if (sharedByUser) {
            return sharedByUser?.firstName + " " + sharedByUser?.lastName;
        }
        return "";
    }
};

const Workspaces = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");
    const spaces = useSelector((state) => state.spaceListData);
    const refreshLocation = useSelector((state) => state.refreshLocation);
    const activeWorkspace = useSelector((state) => state.activeWorkspace);
    // const directories = useSelector((state) => state.directories);
    const redirectionObject = useSelector((state) => state.redirectionObject);
    const emailRecieved = useSelector((state) => state.emailRecieved);
    const [selectedFileData, setSelectedFileData] = useState(null);
    const { data: users } = useUsersQuery();
    const [isCheckComposer, setIsCheckComposer] = useState(false);
    const [noteText, setNoteText] = useState("");
    const [checkType, setCheckType] = useState("");
    const [loading, setLoading] = useState(false);
    const [activeSpace, setActiveSpace] = useState(null);
    const [rowsSelected, setRowsSelected] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [breadcrumbData, setBreadcrumbData] = useState([]);
    const [openSpace, setOpenSpace] = useState(false);
    const [spaceTableData, setSpaceTableData] = useState(null);
    const [filteredRow, setFilteredRow] = useState(null);
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [fileName, setFileName] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [fileLocation, setFileLocation] = useState(null);
    const [ComposerModalShow, setComposerModalShow] = useState(false);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("");

    useEffect(() => {
        if (emailRecieved) {
            const activeFolder = breadcrumbData[breadcrumbData.length - 1];
            const targetFolders = emailRecieved
                .split(",")
                .map((folderId) => parseInt(folderId));
            if (targetFolders.includes(parseInt(activeFolder?.id))) {
                fetchFolderData(activeFolder, true);
                dispatch(setEmailRecievedAction(null));
            }
        }
    }, [emailRecieved]);
    useEffect(() => {
        if (refreshLocation) {
            let activeLocation = breadcrumbData[breadcrumbData.length - 1];
            if (
                activeLocation.type === "workspace" &&
                activeLocation.id === refreshLocation.workspaceId
            ) {
                dispatch(fetchSpaceListAction(refreshLocation.workspaceId));
            } else if (
                activeLocation.type === "space" &&
                activeLocation.id === refreshLocation.spaceId
            ) {
                let data = {};
                data.spaceId = refreshLocation.spaceId;
                data.id = refreshLocation.folderId
                    ? refreshLocation.folderId
                    : 0;
                fetchFolderData(data, true, true);
            } else {
                if (activeLocation.id === refreshLocation.folderId) {
                    let data = {};
                    data.spaceId = refreshLocation.spaceId;
                    data.id = refreshLocation.folderId;
                    fetchFolderData(data, true, true);
                }
            }
        }
        dispatch(setRefreshLocation(null));
    }, [refreshLocation]);

    useEffect(() => {
        dispatch(fetchDirectoriesAction());
        return () => {
            dispatch(setActiveWorkspaceAction(null));
            dispatch(setSpaceListAction([]));
            localStorage.removeItem("redirectionObject");
        };
    }, []);
    useEffect(() => {
        setActiveSpace(null);
        setRowsSelected([]);
        setSelectedValues([]);
        setBreadcrumbData([]);
        setOpenSpace(false);
        setSpaceTableData(null);
        // if (spaces && spaces.length) {
        if (activeWorkspace) {
            if (!redirectionObject) {
                createSpaceTableRows(spaces);
                setBreadcrumbData([
                    {
                        id: activeWorkspace.workspaceId,
                        name: activeWorkspace.workspaceName,
                        type: "workspace",
                        parentId: 0,
                        sharedBy: activeWorkspace?.sharedBy,
                    },
                ]);
            }
        }
    }, [spaces]);
    useEffect(() => {
        if (openSpace) setSpaceTableData(null);
    }, [openSpace]);
    // useEffect(() => {
    //     if (directories && directories.length)
    //         dispatch(fetchDirectoryUsersAction(directories[0].directoryId));
    // }, [directories]);

    const handleDocumentModalClose = () => {
        setShowDocumentModal(false);
        setFileLocation(null);
        setFileName("");
        setIsChecked(false);
    };
    const fetchFolderData = (row, isBreadcrumbClick, isRedirection) => {
        const url = isRedirection
            ? `spacePersistence/folderData?spaceId=${row.spaceId}&folderId=${row.id}`
            : `spacePersistence/folderData?spaceId=${
                  activeSpace ? activeSpace.id : row.id
              }&folderId=${row.type === "space" ? 0 : row.id}`;
        setSpaceTableData(null);
        setLoading(true);
        http.sendRestRequest(url)
            .then((response) => {
                if (!isBreadcrumbClick && !redirectionObject)
                    setBreadcrumbData((state) => [
                        ...state,
                        {
                            id: row.id,
                            name: row.name,
                            type: row.type,
                            parentId: activeWorkspace.workspaceId,
                            sharedBy: row?.sharedBy,
                        },
                    ]);
                createFolderTableRows(response);
                setLoading(false);
                dispatch(setWorkspaceRedirectionAction(null));
            })
            .catch((error) => {
                setLoading(false);
                handleBreadcrumbClick(
                    null,
                    breadcrumbData[breadcrumbData.length - 1]
                );
                dispatch(
                    setSnackbarAction({
                        open: true,
                        severity: "error",
                        message:
                            error?.error || "Error while fetching folder data",
                    })
                );
            });
    };

    const fetchFileData = (row) => {
        const activeFolder = breadcrumbData[breadcrumbData.length - 1];
        setSelectedFileData({
            workSpaceId: activeWorkspace.workspaceId,
            spaceId: activeSpace?.id,
            documentId: row.documentId,
            folderId: activeFolder.type === "folder" ? activeFolder.id : 0,
        });
        setLoading(true);
        http.sendRestRequest(
            `document/documentDetail?documentId=${row.documentId}`
        )
            .then((response) => {
                setLoading(false);
                setFileName(row.name);
                setIsChecked(row.allowDownload);
                if (response && response.documentStream) {
                    const fileType = response.documentName.split(".").pop();

                    if (row.isEditable && fileType === "html") {
                        setIsCheckComposer(true);
                        const value = decodeBase64(response.documentStream);
                        setNoteText(value);
                    } else {
                        setShowDocumentModal(true);
                        if (fileType === "pdf") {
                            setTimeout(() => {
                                setFileLocation(response);
                            }, 1);
                        } else {
                            setFileLocation(response);
                        }
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                dispatch(
                    setSnackbarAction({
                        open: true,
                        severity: "error",
                        message:
                            error?.error || "Error while fetching file data",
                    })
                );
            });
    };
    const recentViews = (id, name, type) => {
        // setShowLoader(true);
        let url = "userInteractions/workspace/recentlyViewed";
        let viewdata = {
            objectId: id,
            objectName: name,
            objectType: type,
            spaceId: activeSpace ? activeSpace.id : id,
        };
        let data = {
            workspaceId: activeWorkspace.workspaceId,
            descrption: JSON.stringify(viewdata),
        };
        http.sendRestRequest(url, "POST", data)
            .then((resp) => {
                // setShowLoader(false);
                // fetchFolderListData(selectedSpace.id);
            })
            .catch((err) => {
                // setShowLoader(false);
            });
    };
    const decodeBase64 = (base64String) => {
        try {
            return atob(base64String);
        } catch (e) {
            console.error("Failed to decode Base64 string", e);
            return "";
        }
    };

    const cellClickAction = (e, row, isStarr) => {
        e.stopPropagation();
        if (isStarr) {
            let url = `${
                row.type === "space" ? "space" : "spacePersistence"
            }/starred`;
            let data = null;
            if (row.type === "space")
                data = {
                    spaceId: parseInt(row.id),
                    isStarred: !row.isStarred,
                };
            else
                data = {
                    spacePersistenceId: parseInt(row.id),
                    isStarred: !row.isStarred,
                };
            setLoading(true);
            http.sendRestRequest(url, "PUT", data)
                .then((resp) => {
                    setLoading(false);
                    const b_len = breadcrumbData.length;
                    if (b_len > 1) {
                        fetchFolderData(breadcrumbData[b_len - 1], true);
                    } else
                        dispatch(
                            fetchSpaceListAction(activeWorkspace.workspaceId)
                        );
                })
                .catch((err) => {
                    setLoading(false);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            severity: "error",
                            message:
                                err?.error ||
                                "Error while adding starred items",
                        })
                    );
                });
        } else if (row.type) {
            if (breadcrumbData.length === 1) {
                setOpenSpace(true);
                setActiveSpace(row);
            }
            if (row.type !== "file") {
                recentViews(row.id, row.name, row.type);
                fetchFolderData(row);
            } else fetchFileData(row);
        }
    };
    const createFolderTableRows = (folders) => {
        let rows = [];
        for (const key in folders) {
            if (Object.hasOwnProperty.call(folders, key)) {
                const element = folders[key];

                rows.push({
                    id: key,
                    name: element.objectName,
                    // updatedOn: convertUTCToLocal(element.updatedOn),
                    // createdOn: convertUTCToLocal(element.createdOn),
                    createdOn: element.createdOn,
                    updatedOn: element.updatedOn,
                    isShared: element.isShared ? "Shared" : "Personal",
                    owner: getOwnerName(users, element.sharedBy),
                    isStarred: element.isStarred,
                    type: element.documentId === 0 ? "folder" : "file",
                    documentId: element.documentId || null,
                    isEditable: element.isEditable,
                    allowDownload: element.allowDownload,
                    sharedBy: element.sharedBy,
                });
            }
        }
        setSpaceTableData({ columns, rows });
    };
    const createSpaceTableRows = (spaces) => {
        let rows = [];
        spaces.forEach((space) => {
            rows.push({
                id: space.spaceId,
                name: space.spaceName,
                // updatedOn: convertUTCToLocal(element.updatedOn),
                // createdOn: convertUTCToLocal(element.createdOn),
                createdOn: space.createdOn,
                updatedOn: space.updatedOn,
                isShared: space.isShared ? "Shared" : "Personal",
                owner: getOwnerName(users, space.sharedBy),
                isStarred: space.isStarred,
                type: "space",
                documentId: null,
                sharedBy: space.sharedBy,
            });
        });
        setSpaceTableData({ columns, rows });
    };
    const setCurrentWorkspace = (workspace) => {
        localStorage.setItem("workspaceId", workspace.workspaceId);
        http.sendRestRequest(
            `workspace/spaceList?workspaceId=${workspace.workspaceId}`
        )
            .then((response) => {
                dispatch(setActiveWorkspaceAction(workspace));
                dispatch(setSpaceListAction(response));
                dispatch(setActivePageAction("Workspace"));
            })
            .catch((err) => console.log(err));
    };
    const handleBreadcrumbClick = (event, item) => {
        event?.preventDefault();
        setRowsSelected([]);
        setSelectedValues([]);
        const indexOfClickedItem = breadcrumbData.indexOf(item);
        setBreadcrumbData((breadcrumb) =>
            breadcrumb.slice(0, indexOfClickedItem + 1)
        );
        if (indexOfClickedItem === 0) {
            setCurrentWorkspace(activeWorkspace);
            setOpenSpace(false);
            createSpaceTableRows(spaces);
            setActiveSpace(null);
        } else if (indexOfClickedItem === 1) {
            fetchFolderData({ id: 0, name: item.name }, true);
        } else {
            fetchFolderData(item, true);
        }
    };
    const handleRedirection = (redirectionObject, type) => {
        setRowsSelected([]);
        setSelectedValues([]);
        setBreadcrumbData(redirectionObject.breadCrumbItems);
        fetchFolderData(
            {
                id: redirectionObject.folderId,
                name: redirectionObject.folderName,
                spaceId: redirectionObject.activeSpace.id,
            },
            true,
            true
        );
        setActiveSpace(redirectionObject.activeSpace);
    };

    const onSearchHandler = (event) => {
        const searchText = event.target.value.toLowerCase(); // Convert search text to lowercase
        setSearchText(searchText);

        if (spaceTableData && spaceTableData.rows) {
            const filteredRows = spaceTableData.rows.filter(
                (row) => row.name.toLowerCase().includes(searchText) // Convert row name to lowercase before comparing
            );
            setFilteredRow({ columns, rows: filteredRows });
        }
    };

    useEffect(() => {
        if (redirectionObject) {
            let basePathName = window.runtimeConfig.instanceName
                ? "/" + window.runtimeConfig.instanceName
                : "";
            localStorage.setItem("redirectionObject", true);
            http.sendRestRequest(
                `workspace/spaceList?workspaceId=${redirectionObject?.activeWorkspace?.workspaceId}`
            )
                .then((resp) => {
                    dispatch(setSpaceListAction(resp));
                    navigate(`${basePathName}/workspace`);
                    dispatch(
                        setActiveWorkspaceAction(
                            redirectionObject.activeWorkspace
                        )
                    );
                    setActiveSpace(redirectionObject.activeSpace);
                    handleRedirection(redirectionObject);
                })
                .catch((err) => {
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            severity: "error",
                            message: err?.error || "Error while redirecting",
                        })
                    );
                });
        }
    }, [redirectionObject]);

    return (
        <div className="main-container">
            <div className="workspace-breadcrumb">
                <Breadcrumb
                    email={breadcrumbData.some(
                        (item) => item.name === "Emails"
                    )}
                    flow={breadcrumbData.some((item) => item.name === "Flow")}
                    breadcrumbData={breadcrumbData}
                    handleBreadcrumbClick={handleBreadcrumbClick}
                ></Breadcrumb>
            </div>
            {breadcrumbData.some((item) => item.name === "Flow") ? (
                <div className="workspace-icons">
                    <RightActions
                        searchText={searchText}
                        onSearchHandler={onSearchHandler}
                        handleRedirection={handleRedirection}
                        breadcrumb={breadcrumbData.some(
                            (item) => item.name === "Flow"
                        )}
                        breadcrumbData={breadcrumbData}
                        rowsSelected={rowsSelected}
                        currentRows={spaceTableData ? spaceTableData.rows : []}
                    />
                </div>
            ) : breadcrumbData.some((item) => item.name === "Emails") ? (
                <div className="workspace-icons">
                    {breadcrumbData.length > 1 && rowsSelected.length > 0 && (
                        <LeftActions
                            checkType={checkType}
                            isEmail={
                                breadcrumbData.some(
                                    (item) => item.name === "Emails"
                                )
                                    ? true
                                    : false
                            }
                            isSelection={rowsSelected.length > 0}
                            isSpace={breadcrumbData.length > 1}
                            isFolder={openSpace}
                            isFile={false}
                            setNoteText={setNoteText}
                            setFileName={setFileName}
                            currentRows={
                                spaceTableData ? spaceTableData.rows : []
                            }
                            activeSpace={activeSpace}
                            selectedValues={selectedValues}
                            parent={
                                breadcrumbData.length === 1
                                    ? breadcrumbData[0]
                                    : breadcrumbData.length > 2
                                    ? breadcrumbData[breadcrumbData.length - 1]
                                    : breadcrumbData[1]
                            }
                            fetchFolderData={fetchFolderData}
                            selectedRows={rowsSelected}
                            setComposerModalShow={setComposerModalShow}
                        />
                    )}
                    {
                        <RightActions
                            searchText={searchText}
                            onSearchHandler={onSearchHandler}
                            handleRedirection={handleRedirection}
                            breadcrumb={breadcrumbData.some(
                                (item) => item.name === "Flow"
                            )}
                            breadcrumbData={breadcrumbData}
                            rowsSelected={rowsSelected}
                            currentRows={
                                spaceTableData ? spaceTableData.rows : []
                            }
                        />
                    }
                </div>
            ) : (
                <div className="workspace-icons">
                    <LeftActions
                        checkType={checkType}
                        isSelection={rowsSelected.length > 0}
                        isSpace={breadcrumbData.length > 1}
                        isFolder={openSpace}
                        isFile={false}
                        setNoteText={setNoteText}
                        setFileName={setFileName}
                        currentRows={spaceTableData ? spaceTableData.rows : []}
                        activeSpace={activeSpace}
                        selectedValues={selectedValues}
                        parent={
                            breadcrumbData.length === 1
                                ? breadcrumbData[0]
                                : breadcrumbData.length > 2
                                ? breadcrumbData[breadcrumbData.length - 1]
                                : breadcrumbData[1]
                        }
                        fetchFolderData={fetchFolderData}
                        selectedRows={rowsSelected}
                        setComposerModalShow={setComposerModalShow}
                    />
                    <RightActions
                        handleRedirection={handleRedirection}
                        breadcrumbData={breadcrumbData}
                        rowsSelected={rowsSelected}
                        currentRows={spaceTableData ? spaceTableData.rows : []}
                        activeSpace={activeSpace}
                    />
                </div>
            )}
            <div className="workspace-content">
                {!spaceTableData || !spaceTableData.rows ? (
                    <Box sx={{ width: "100%", height: "100%" }}>
                        <Paper
                            sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                            elevation={3}
                        ></Paper>
                    </Box>
                ) : spaceTableData.rows.length === 0 ? (
                    <EmptyTable
                        isWorkspace={breadcrumbData.length === 1}
                        fetchFolderData={fetchFolderData}
                        activeSpace={activeSpace}
                        breadcrumbData={breadcrumbData}
                        parent={
                            breadcrumbData.length === 1
                                ? breadcrumbData[0]
                                : breadcrumbData.length > 2
                                ? breadcrumbData[breadcrumbData.length - 1]
                                : breadcrumbData[1]
                        }
                    />
                ) : (
                    <CustomTable
                        order={order}
                        setOrder={setOrder}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        setCheckType={setCheckType}
                        breadcrumbData={breadcrumbData}
                        rowsSelected={rowsSelected}
                        setRowsSelected={setRowsSelected}
                        setSelectedValues={setSelectedValues}
                        onSearchHandler={onSearchHandler}
                        spaceTableData={spaceTableData}
                        searchText={searchText}
                        selectedValues={selectedValues}
                        filteredRow={filteredRow}
                        setSearchText={setSearchText}
                        cellClickAction={cellClickAction}
                    />
                )}
            </div>
            {showDocumentModal ? (
                <ModalView
                    showHeader={true}
                    footer="false"
                    show={showDocumentModal}
                    size="lg"
                    documentId={selectedFileData.documentId}
                    onHide={handleDocumentModalClose}
                    title={fileName}
                    isChecked={isChecked}
                    modalBodyClass="documentViewModal"
                    onclick={() => {}}
                    submitButtonText="Close"
                    headerTitle={fileName}
                    module="workSpace"
                    selectedFileData={selectedFileData}
                >
                    {showDocumentModal ? (
                        <DocViwer
                            docName={fileName}
                            fileLocation={fileLocation}
                        ></DocViwer>
                    ) : null}
                </ModalView>
            ) : null}

            {ComposerModalShow || isCheckComposer ? (
                <ComposerModal
                    setShowDocumentModal={setShowDocumentModal}
                    setComposerModalShow={setComposerModalShow}
                    selectedSpace={activeSpace}
                    isCheckComposer={isCheckComposer}
                    setIsCheckComposer={setIsCheckComposer}
                    parentFolderId={
                        breadcrumbData.length === 2
                            ? 0
                            : breadcrumbData[breadcrumbData.length - 1]?.id
                    }
                    noteString={noteText}
                    title={fileName}
                    parent={
                        breadcrumbData.length === 1
                            ? breadcrumbData[0]
                            : breadcrumbData.length > 2
                            ? breadcrumbData[breadcrumbData.length - 1]
                            : breadcrumbData[1]
                    }
                    show={ComposerModalShow || isCheckComposer}
                    onHide={() => {
                        setIsCheckComposer(false);
                        setComposerModalShow(false);
                    }}
                    fetchFolderData={fetchFolderData}
                ></ComposerModal>
            ) : null}
            <Loader show={loading} />
        </div>
    );
};

export default Workspaces;
