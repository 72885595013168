import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setAlertLogData,
    setHomeAlertLogData,
    setInstanceList,
} from "../../../../actions/DocumentChainActions";
import http from "../../../../bae-client/HTTP";
import { setSnackbarAction } from "../../../../services/services/app";

const useAlertData = (url) => {
    const [data, setData] = useState({ action: "", data: null });
    const alertLogData = useSelector((state) => state.alertLogData);
    const homeAlertLogData = useSelector((state) => state.homeAlertLogData);
    const InstanceList = useSelector((state) => state.InstanceList);

    const dispatch = useDispatch();

    useEffect(() => {
        if (data.action) {
            if (data.action === "setAlerts") {
                if (alertLogData?.length > 0)
                    dispatch(setAlertLogData([...alertLogData, data.data]));
                else dispatch(setAlertLogData([data.data]));
            } else if (data.action === "setHomeAlerts") {
                let tempAlerts = homeAlertLogData;
                if (tempAlerts?.length > 0) {
                    tempAlerts.pop();
                    dispatch(setHomeAlertLogData([data.data, ...tempAlerts]));
                }
            } else if (data.action === "closeAlert") {
                let alertRegistryId = data.data;
                const filterdAlerts = alertLogData?.filter(
                    (alert) =>
                        alert.alertRegistryId !== alertRegistryId &&
                        alert.status !== "CLOSED"
                );
                if (filterdAlerts?.length > 0)
                    dispatch(setAlertLogData(filterdAlerts));
            } else if (data.action === "closeException") {
                let exceptionLogObjectId = data.data;
                const filterdAlerts = alertLogData?.filter(
                    (alert) =>
                        alert.exceptionLogObjectId !== exceptionLogObjectId &&
                        alert.status !== "CLOSED"
                );
                if (filterdAlerts?.length > 0)
                    dispatch(setAlertLogData(filterdAlerts));
            } else if (data.action === "newInstance") {
                const message = data.data;
                const splitArray = message.split("/");
                if (splitArray.length > 1) {
                    const instanceData = splitArray[1];
                    const genericData = splitArray[0];

                    if (instanceData) {
                        const instanceDataArray = instanceData.split(":");
                        const genericDataArray = genericData.split(":");

                        if (
                            instanceDataArray.length > 1 &&
                            genericDataArray.length > 1
                        ) {
                            const instanceId = instanceDataArray[1];
                            const genericId = genericDataArray[1];

                            if (instanceId && genericId) {
                                let url = `documentChain/instance/${genericId}/${instanceId}`;
                                http.sendRestRequest(url, "GET")
                                    .then((resp) => {
                                        const temp = [...InstanceList];
                                        if (temp.length > 0) {
                                            temp.push(resp);
                                            dispatch(setInstanceList(temp));
                                        }
                                        dispatch(
                                            setSnackbarAction({
                                                open: true,
                                                message: "New Instance Added!",
                                                severity: "success",
                                            })
                                        );
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            }
                        }
                    }
                }
            } else setData({ action: "", data: null });
        }
    }, [data]);

    return [data, setData];
};

export default useAlertData;
