import React, { useState, useEffect, useRef } from "react";
import http from "../../../../../bae-client/HTTP";
import RecentActivityManager from "../../../../../bae-client/RecentActivityManager";
import * as Mixpanel from "../../../../../mix-panel/Mixpanel";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import {
    addSpaceAction,
    fetchSpaceListAction,
    setSnackbarAction,
} from "../../../../../services/services/app";
import WorkspaceModal from "./workspace-modal";
import Loader from "../../../../stylized/Loader";
import { validateFileType } from "./constant";
import { ButtonComponent } from "../../../../button/button";
import ShareFile from "../WorkspaceContainer/AddFolderModal/ShareFile";
import Move from "../communicator/chatSection/chatHistory/shareOptions/Move";
import AlertDialog from "../../../../stylized/AlertDialog";
import { fetchHierarchyResponse } from "../../../../../actions/HierarchyActions";
import { fetchUserListData } from "../../LeftPane/Directory/DirectoryActions";
const Actions = ({
    isEmail,
    isSelection = false,
    isSpace = true,
    isFolder = false,
    isFile,
    currentRows,
    activeSpace,
    parent,
    fetchFolderData,
    checkType,
    selectedValues,
    selectedRows,
    setComposerModalShow,
    setFileName,
    setNoteText,
}) => {
    const [activeFolderItems, setActiveFolderItems] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const directoryList = useSelector((state) => state.directoryList);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [modalType, setModalType] = useState("");
    const [existingName, setExistingName] = useState("");
    const [existingMatcher, setExistingMatcher] = useState([]);
    const [actionButtonName, setActionButtonName] = useState("Save");
    const [content, setContent] = useState("");
    const directories = useSelector((state) => state.directories);
    const [shareFile, setShareFile] = useState(false);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [disableOperations, setDisableOperations] = useState(false);
    const [disableDownloads, setDisableDownloads] = useState(false);
    const tableFileUplode = useRef(null);
    const dispatch = useDispatch();
    const spaceListData = useSelector((state) => state.spaceListData);
    const activeWorkspace = useSelector((state) => state.activeWorkspace);
    const [recentlyCreatedSpace, setRecentlyCreatedSpace] = useState("");
    useEffect(() => {
        if (directories && directories.length && shareFile) {
            dispatch(
                fetchUserListData(
                    directories[directories.length - 1].directoryId
                )
            );
        }
    }, [shareFile]);
    useEffect(() => {
        if (
            activeWorkspace &&
            activeWorkspace.workspaceType === "SYSTEM" &&
            activeWorkspace &&
            activeWorkspace.workspaceName !== "Emails"
        )
            setDisableOperations(true);
        else setDisableOperations(false);
    }, [activeWorkspace]);
    useEffect(() => {
        const hasNonEditable = selectedValues.some((row) => !row.allowDownload);
        const hasFolder = selectedValues.some((row) => row.type === "folder");

        if (hasNonEditable || hasFolder) {
            setDisableDownloads(true);
        } else {
            setDisableDownloads(false);
        }
    }, [selectedValues]);

    useEffect(() => {
        if (recentlyCreatedSpace !== "") {
            let recentSpace = spaceListData.find(
                (item) => item.spaceName.trim() === recentlyCreatedSpace.trim()
            );
            let recentActivity = new RecentActivityManager();
            let dataObj = {
                objectType: "SPACE",
                objectId: recentSpace.spaceId,
                description: `Created ${recentSpace.spaceName}`,
                path: `${activeWorkspace.workspaceId}`,
            };
            recentActivity
                .createRecentActivity(dataObj)
                .then((recentRes) => {})
                .catch((err) => console.log(err.error));
            setRecentlyCreatedSpace("");
        }
    }, [spaceListData]);
    const clearStates = () => {
        setModalTitle("");
        setModalType("");
        setExistingMatcher([]);
        setExistingName("");
        setShowLoader(false);
        setActionButtonName("Save");
        setContent("");
    };
    const handleNewNoteClick = () => {
        setNoteText("");
        setFileName("");
        setComposerModalShow(true);
    };
    const handleAddSpaceOrFolderClick = () => {
        const row = { ...parent };
        setContent("");
        if (row.type === "workspace") {
            setModalTitle("Add New Space");
            setModalType("Space");
            setExistingMatcher(spaceListData.map((space) => space.spaceName));
            setActionButtonName("Add Space");
        } else {
            setModalTitle("Add New Folder");
            setModalType("Folder");
            setExistingMatcher([...activeFolderItems]);
            setActionButtonName("Add Folder");
        }

        setOpen(true);
    };
    const handleDeleteClick = () => {
        const row = { ...parent };
        if (row.type === "workspace") {
            setModalTitle("Delete Space");
            setModalType("Space");
            setContent("Are you sure you want to delete selected space?");
        } else {
            setModalTitle("Delete Item");
            setModalType("Item");
            setContent("Are you sure you want to delete selected item?");
        }
        setActionButtonName("Delete");
        setOpen(true);
    };
    const handleArchiveClick = () => {
        const row = { ...parent };
        if (row.type === "workspace") {
            setModalTitle("Archive Space");
            setModalType("Space");
            setContent("Are you sure you want to archive selected space?");
        } else {
            setModalTitle("Archive Item");
            setModalType("Item");
            setContent("Are you sure you want to archive selected item?");
        }
        setActionButtonName("Archive");
        setOpen(true);
    };
    const handleShareClick = () => {
        if (selectedRows?.length > 1)
            dispatch(
                setSnackbarAction({
                    open: true,
                    message: "Multiple items cannot be shared at a time",
                    severity: "error",
                })
            );
        else {
            setShareFile(true);
        }
    };

    const onChangeFileUpload = (event) => {
        let filesData = event.target ? event.target.files : event;
        if (!filesData.length) return;
        let formFileData = Array.from(filesData);
        let fileError = false;
        if (formFileData.length > 0) {
            let chosenFile = Array.from(formFileData);
            chosenFile.forEach((files) => {
                let fileNames = [];
                let fileSizeArray = [];
                let fileName = files.name;
                fileNames.push(fileName);
                let fileSize = files.size;
                let maxSizeLimit = window.runtimeConfig.maxFileSize; //in mb
                let fileSizeInMB = fileSize / 1024 / 1024;
                fileSizeArray.push(fileSizeInMB);
                let isValidFileType = validateFileType(fileName);
                if (!isValidFileType) {
                    fileError = true;
                    setShowAlertDialog(true);
                    setAlertDialogMessage("File Type Is Not Supported.");
                    return;
                }

                if (fileSizeInMB > maxSizeLimit) {
                    fileError = true;
                    setShowAlertDialog(true);
                    setAlertDialogMessage(
                        "File size should be less than 100MB"
                    );
                    return;
                }
                if (fileError === true) return;
                const spaceId = activeSpace.id;
                let parentId = parent.id === spaceId ? 0 : parent.id;
                const data = new FormData();
                data.append("file", files, encodeURIComponent(fileName));
                setShowLoader(true);
                http.uploadFile(
                    data,
                    `rest/spacePersistence/file?spaceId=${spaceId}&folderId=${parentId}`,
                    "POST"
                )
                    .then((response) => {
                        if (response.status === 200) {
                            fileNames.forEach((fileName, index) => {
                                let fileExt = fileName.split(".").pop();
                                let fileSize = fileSizeArray[index] + "MB";
                                Mixpanel.callFileManagementEvent(
                                    "Upload",
                                    fileExt,
                                    fileSize
                                );
                            });
                            setShowLoader(false);
                            dispatch(
                                setSnackbarAction({
                                    open: true,
                                    message: "Document uploaded successfully",
                                })
                            );
                            fetchFolderData(
                                parent.id === spaceId ? { id: 0 } : parent,
                                true
                            );
                        } else {
                            setShowLoader(false);
                        }
                    })
                    .catch((err) => {
                        setShowLoader(false);
                    });
            });
        }
    };
    const addNewFolder = (folderName) => {
        setShowLoader(true);
        let spaceId = activeSpace.id;
        let data = {
            spaceId: spaceId,
            objectName: folderName.trim(),
            parentRowId: parent.id === spaceId ? 0 : parent.id,
        };

        let url = "spacePersistence/folder";
        http.sendRestRequest(url, "POST", data)
            .then((resp) => {
                if (resp) {
                    let key = Object.keys(resp);
                    let recentActivity = new RecentActivityManager();
                    let dataobj = {
                        objectType: "FOLDER",
                        objectId: parseInt(key[0]),
                        description: `Created ${data.objectName}`,
                        path: `${activeWorkspace.workspaceId}//${activeSpace.id}`,
                    };
                    recentActivity
                        .createRecentActivity(dataobj)
                        .then((recentRes) => {
                            clearStates();
                        })
                        .catch((err) => console.log(err.error));
                    Mixpanel.callFolderManagementEvent("Add");
                    clearStates();
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: "Folder added successfully",
                        })
                    );
                    fetchFolderData(
                        parent.id === spaceId ? { id: 0 } : parent,
                        true
                    );
                }
            })
            .catch((err) => {
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: err.error,
                        severity: "error",
                    })
                );
            });
    };
    const addNewSpace = (spaceName) => {
        let data = {};
        data = {
            spaceName: spaceName.trim(),
            workspaceId: activeWorkspace.workspaceId,
        };
        setShowLoader(true);
        dispatch(addSpaceAction(data))
            .unwrap()
            .then((response) => {
                if (response && response.hasOwnProperty("errorCode")) {
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: response.error,
                            severity: "error",
                        })
                    );
                } else {
                    setRecentlyCreatedSpace(data.spaceName);
                    clearStates();
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: "Space added successfully",
                        })
                    );
                    dispatch(fetchSpaceListAction(activeWorkspace.workspaceId));
                }
            })
            .catch((error) => {
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "There is something wrong. Please try again",
                        severity: "error",
                    })
                );
            });
    };

    const renameFolder = (row, updatedValue) => {
        let data = {
            spacePersistenceId: row.id,
            objectName: updatedValue.trim(),
        };
        setShowLoader(true);
        http.sendRestRequest("spacePersistence/folder", "PUT", data)
            .then(() => {
                let spaceId = activeSpace.id;
                fetchFolderData(
                    parent.id === spaceId ? { id: 0 } : parent,
                    true
                );
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "Folder renamed successfully",
                    })
                );
            })
            .catch((error) => {
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: error.error,
                        severity: "error",
                    })
                );
            });
    };
    const renameSpace = (row, updatedValue) => {
        let data = {
            spaceId: row.id,
            spaceName: updatedValue.trim(),
        };
        setShowLoader(true);
        http.sendRestRequest(
            `space/space?workspaceId=${activeWorkspace.workspaceId}`,
            "PUT",
            data
        )
            .then(() => {
                dispatch(fetchSpaceListAction(activeWorkspace.workspaceId));
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "Space renamed successfully",
                    })
                );
            })
            .catch((error) => {
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: error.error,
                        severity: "error",
                    })
                );
            });
    };
    const deleteFolder = () => {
        const spaceId = activeSpace.id;
        setShowLoader(true);
        http.sendRestRequest(
            `spacePersistence/objects?spaceId=${spaceId}`,
            "DELETE",
            selectedRows
        )
            .then((resp) => {
                let spaceId = activeSpace.id;
                fetchFolderData(
                    parent.id === spaceId ? { id: 0 } : parent,
                    true
                );
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: `${selectedRows?.length} Item deleted successfully`,
                    })
                );
            })
            .catch((err) => {
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "There is something wrong. Please try again",
                        type: "error",
                    })
                );
            });
    };
    const deleteSpace = () => {
        const workspaceId = activeWorkspace.workspaceId;
        setShowLoader(true);
        http.sendRestRequest(
            `space/spaces?workspaceId=${workspaceId}`,
            "DELETE",
            selectedRows
        )
            .then((resp) => {
                dispatch(fetchSpaceListAction(activeWorkspace.workspaceId));
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "Space deleted successfully",
                    })
                );
            })
            .catch((err) => {
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "There is something wrong. Please try again",
                        type: "error",
                    })
                );
            });
    };
    const archiveFolder = () => {
        const spaceId = activeSpace.id;
        const url = `spacePersistence/object/?spaceId=${spaceId}`;
        setShowLoader(true);
        http.sendRestRequest(url, "PUT", selectedRows)
            .then((resp) => {
                let spaceId = activeSpace.id;
                fetchFolderData(
                    parent.id === spaceId ? { id: 0 } : parent,
                    true
                );
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: `${selectedRows?.length} Item archived successfully`,
                    })
                );
            })
            .catch((err) => {
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "There is something wrong. Please try again",
                        type: "error",
                    })
                );
            });
    };
    const archiveSpace = () => {
        const workspaceId = activeWorkspace.workspaceId;
        const url = `space/archive?workspaceId=${workspaceId}`;
        setShowLoader(true);
        http.sendRestRequest(url, "PUT", selectedRows)
            .then((resp) => {
                dispatch(fetchSpaceListAction(activeWorkspace.workspaceId));
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "Space archived successfully",
                    })
                );
            })
            .catch((err) => {
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "There is something wrong. Please try again",
                        type: "error",
                    })
                );
            });
    };

    const handleRenameClick = () => {
        const selectedRow = currentRows.filter((row) =>
            selectedRows.includes(row.id)
        );
        const row = selectedRow[0];
        if (row.type === "folder") {
            setModalTitle("Rename Folder");
            setModalType("Folder");
            setExistingMatcher([...activeFolderItems]);
            setExistingName(row.name);
            setActionButtonName("Rename Folder");
        }
        if (row.type === "space") {
            setModalTitle("Rename Space");
            setModalType("Space");
            setExistingMatcher(spaceListData.map((space) => space.spaceName));
            setExistingName(row.name);
            setActionButtonName("Rename Space");
        }
        setOpen(true);
    };
    const handleDownloadClick = () => {
        const selectedItems = currentRows.filter((r) =>
            selectedRows.includes(r.id)
        );

        selectedItems.forEach((item) => {
            if (!item.documentId) {
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "Folder cannot be downloaded!",
                        type: "error",
                    })
                );
                return;
            }
            const hasallowDownload = selectedValues.some(
                (row) => !row.allowDownload
            );
            if (hasallowDownload) {
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message:
                            "File cannot be downloaded as Some files are not downloadable",
                        type: "error",
                    })
                );
                return;
            }
            http.sendRestRequest(
                `document/documentDetail?documentId=${item.documentId}`
            )
                .then((response) => {
                    const baseUrl = window.location.origin;
                    setShowLoader(false);
                    // setCurrentShowFileName(response.documentName);
                    saveAs(
                        baseUrl +
                            "/" +
                            response.documentLocation +
                            "/" +
                            item.name,
                        item.name
                    );
                    let fileExt = item.name.split(".").pop();
                    Mixpanel.callFileManagementEvent("Download", fileExt);
                })
                .catch((error) => {
                    setShowLoader(false);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message:
                                error?.error ||
                                "Something went wrong. Please try again",
                            type: "error",
                        })
                    );
                });
        });
    };
    const handleModalButtonAction = (updatedValue) => {
        const selectedRow = currentRows.filter((row) =>
            selectedRows.includes(row.id)
        );
        const row = selectedRow[0];
        if (modalType === "Folder" || modalType === "Item") {
            if (modalTitle === "Rename Folder") {
                renameFolder(row, updatedValue);
            } else if (modalTitle === "Add New Folder") {
                addNewFolder(updatedValue);
            } else if (modalTitle === "Delete Item") {
                deleteFolder();
            } else if (modalTitle === "Archive Item") {
                archiveFolder();
            }
        } else {
            if (modalTitle === "Rename Space") {
                renameSpace(row, updatedValue);
            } else if (modalTitle === "Add New Space") {
                addNewSpace(updatedValue);
            } else if (modalTitle === "Delete Space") {
                deleteSpace();
            } else if (modalTitle === "Archive Space") {
                archiveSpace();
            }
        }
    };
    useEffect(() => {
        if (currentRows.length > 0) {
            const array = [];
            currentRows.forEach((element) => {
                if (element.type === "folder") {
                    array.push(element.name);
                }
            });
            setActiveFolderItems(array);
        }
    }, [currentRows]);
    const checkFolder = () => {
        if (currentRows.length === 0) {
            return false;
        }
        const selectedRow = currentRows.find((row) =>
            selectedRows.includes(row.id)
        );
        let isFolder =
            selectedRow.type === "folder" || selectedRow.type === "space"
                ? true
                : false;
        return isFolder;
    };
    return (
        <>
            <div className="workspace-actions">
                {!isSelection && !isSpace && !isFolder ? (
                    <ButtonComponent
                        type={"outlined"}
                        icon={"./images/plus.svg"}
                        text={"Add Space"}
                        id="btn_space_addspace"
                        BtnHandler={handleAddSpaceOrFolderClick}
                        disable={disableOperations}
                    />
                ) : (
                    !isSelection &&
                    (isSpace || isFolder) && (
                        <>
                            <ButtonComponent
                                type={"secondary"}
                                icon={"./images/Add Folder.svg"}
                                text={"Add Folder"}
                                id="btn_space_addfolder"
                                BtnHandler={handleAddSpaceOrFolderClick}
                                disable={disableOperations}
                            />
                            <ButtonComponent
                                type={"secondary"}
                                icon={"./images/Upload File.svg"}
                                text={"Upload File"}
                                BtnHandler={() =>
                                    tableFileUplode.current.click()
                                }
                                id="btn_space_addfile"
                                disable={disableOperations}
                            />
                            <input
                                type="file"
                                id="file"
                                ref={tableFileUplode}
                                hidden={true}
                                onChange={onChangeFileUpload}
                                onClick={(event) => {
                                    event.currentTarget.value = null;
                                }}
                                multiple
                            />
                        </>
                    )
                )}

                {!isSelection && (isSpace || isFolder) && (
                    <ButtonComponent
                        type={"secondary"}
                        icon={"./images/Add Folder.svg"}
                        text={"New Note"}
                        BtnHandler={handleNewNoteClick}
                        disable={disableOperations}
                    />
                )}

                {isSelection && (
                    <>
                        {selectedRows.length === 1 &&
                            checkFolder() &&
                            // checkType !== "file" &&
                            !isEmail && (
                                <ButtonComponent
                                    type={"secondary"}
                                    icon={"./images/Rename.svg"}
                                    text={"Rename"}
                                    BtnHandler={handleRenameClick}
                                    disable={disableOperations}
                                    id="div_table_rename"
                                />
                            )}
                        <ButtonComponent
                            type={"secondary"}
                            icon={"./images/Archive (1).svg"}
                            text={"Archive"}
                            id="div_table_archive"
                            BtnHandler={handleArchiveClick}
                            disable={disableOperations}
                        />
                        <ButtonComponent
                            type={"secondary"}
                            icon={"./images/trash 2.svg"}
                            text={"Delete"}
                            id="div_table_trash"
                            BtnHandler={handleDeleteClick}
                            disable={disableOperations}
                        />
                        {isEmail === true ? (
                            <Move
                                activeWorkspace={activeWorkspace}
                                folderData={currentRows}
                                itemSpace={activeSpace}
                                isFileOrFolderSelected={true}
                                selectedRowData={currentRows.filter((r) =>
                                    selectedRows.includes(r.id)
                                )}
                                parentFolderId={
                                    parent.id === activeSpace.id ? 0 : parent.id
                                }
                                enable={true}
                                disable={disableOperations}
                                fetchFolderListData={fetchFolderData}
                                parent={parent}
                            />
                        ) : null}

                        {(isSpace || isFolder) && !isEmail && (
                            <>
                                <ButtonComponent
                                    type={"secondary"}
                                    icon={"./images/share 2.svg"}
                                    text={"Share"}
                                    id="div_table_share"
                                    BtnHandler={handleShareClick}
                                    disable={disableOperations}
                                />
                                <Move
                                    activeWorkspace={activeWorkspace}
                                    folderData={currentRows}
                                    itemSpace={activeSpace}
                                    isFileOrFolderSelected={true}
                                    selectedRowData={currentRows.filter((r) =>
                                        selectedRows.includes(r.id)
                                    )}
                                    parentFolderId={
                                        parent.id === activeSpace.id
                                            ? 0
                                            : parent.id
                                    }
                                    enable={true}
                                    disable={disableOperations}
                                    fetchFolderListData={fetchFolderData}
                                    parent={parent}
                                />
                                <ButtonComponent
                                    type={"secondary"}
                                    icon={
                                        "./images/fileTypeIcon/download 1.svg"
                                    }
                                    text={"Download"}
                                    id="div_table_download"
                                    BtnHandler={handleDownloadClick}
                                    disable={disableDownloads}
                                />
                            </>
                        )}
                    </>
                )}
            </div>

            {open && (
                <WorkspaceModal
                    open={open}
                    setOpen={setOpen}
                    textId={
                        modalTitle === "Rename Folder"
                            ? "updateFolderName"
                            : modalTitle === "Add New Folder"
                            ? "newFolderName"
                            : modalTitle === "Rename Folder" ||
                              modalTitle === "Rename Space"
                            ? "updateSpacename"
                            : modalTitle === "Add New Space"
                            ? "spacename"
                            : ""
                    }
                    title={modalTitle}
                    type={modalType}
                    existingName={existingName}
                    existingMatcher={existingMatcher}
                    buttonAction={handleModalButtonAction}
                    actionButtonName={actionButtonName}
                    content={content}
                    setContent={setContent}
                    id={
                        modalTitle === "Delete Item"
                            ? "button_modal_delete"
                            : modalTitle === "Archive Item" ||
                              modalTitle === "Archive Space"
                            ? "button_modal_archive"
                            : modalTitle === "Rename Folder" ||
                              modalTitle === "Rename Space"
                            ? "Change"
                            : modalTitle === "Add New Folder"
                            ? "Add_Folder"
                            : modalTitle === "Delete Space"
                            ? "button_modal_delete"
                            : modalTitle === "Add New Space"
                            ? "Add_Space"
                            : ""
                    }
                />
            )}
            {shareFile && (
                <ShareFile
                    showShareModal={shareFile}
                    closeModal={() => setShareFile(false)}
                    space={activeSpace}
                    activeWorkspace={activeWorkspace}
                    parentFolderId={parent.id}
                    lastSelectedRowData={currentRows.find(
                        (r) => r.id == selectedRows[0]
                    )}
                    folderData={parent}
                />
            )}
            <Loader show={showLoader} />
            <AlertDialog
                open={showAlertDialog}
                setOpen={setShowAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
            ></AlertDialog>
        </>
    );
};

export default Actions;
