import React, { Component } from "react";
import http from "../../../../../bae-client/HTTP";
import userManager from "../../../../../bae-client/UserManager";
import AlertDialog from "../../../../stylized/AlertDialog";
import ChipsView from "../../../../stylized/ChipsView";
import Loader from "../../../../stylized/Loader";
import ModalView from "../../../../stylized/ModalView";
import TextArea from "../../../../stylized/TextArea";
import TextBox from "../../../../stylized/TextBox";
import { connect } from "react-redux";
import { ValidateEmail } from "../../../../../bae-client/Utils";
import { Mixpanel } from "../../../../../mix-panel/Mixpanel";
import { useAutocomplete } from "@mui/core/AutocompleteUnstyled";

class CreateDirectory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
            value: "",
            chipsArray: [],
            invited: false,
            emailError: "",
            messageError: "",
            showAlertDialog: false,
            alertDialogTitle: "",
            alertDialogMessage: "",
            isAlert: false,
            name: "",
            dropdownList: [this.props.chatList],
            selectedOptions: [],
        };

        this.wholeContacts = null;
        this.selectedUsername = "";
        this.searchText = "";
        this.chipsArray = [];
        this.messageBody = "";
    }

    changeOpenDialogFlag = (flag) => {
        this.setState({ showAlertDialog: flag });
    };
    showAlertView = (title, body) => {
        this.setState({
            showAlertView: true,
            alertDialogMessage: body,
            alertDialogTitle: title,
        });
    };
    componentDidMount() {
        if (!this.state.usersLoaded) {
            // Check if users are already loaded
            userManager.loadUsers().then((resp) => {
                let allContacts = userManager.getAllProfiles();
                this.wholeContacts = this.parseLoadedContact(allContacts);
                this.setState({ usersLoaded: true }); // Update state to prevent re-fetch
            });
        }
    }

    parseLoadedContact = (contactRawList) => {
        let contactList = [];
        contactRawList.forEach((key, index) => {
            contactList.push({
                username: key.firstName + " " + key.lastName,
                userEmail: key.userEmail,
                userId: key.userId,
                company: "Not Specified",
                read: "false",
                chatCount: 0,
                profileImg: "A",
            });
        });
        return contactList;
    };
    clearData = () => {
        this.messageBody = "";
        this.setState({ chipsArray: [], emailError: "" });
        this.chipsArray = [];
        this.searchText = "";
    };
    sendInvite = (emails) => {
        let message = "";
        let url = "register/inviteUsers";
        if (this.messageBody !== "") message = this.messageBody;
        else message = "Welcome to the Unity Central!";
        let data = { emailAddresses: emails, message: message };
        this.closeInviteModal();
        this.setState({ showLoader: true });
        http.sendRestRegistraionRequest(url, "POST", data)
            .then((response) => {
                data.forEach((personMailId) => {
                    Mixpanel.track("Sent Invitation", {
                        "Person Invited": personMailId,
                    });
                });
                this.setState({
                    showLoader: false,
                    invited: true,
                });
                this.clearData();
            })
            .catch((err) => {
                this.showAlertView("", err.error);
                this.setState({ showLoader: false });
            });
    };
    handleSendInviteButtonClick = () => {
        let emails = this.getChipsText();
        if (this.searchText.trim() !== "") {
            let isChipCreated = this.createChip(this.searchText.trim());
            if (isChipCreated) {
                emails = this.getChipsText();
            }
        } else if (this.searchText === "" && emails.length) {
            emails = this.getChipsText();
            this.sendInvite(emails);
        } else if (!emails.length) {
            this.setState({ emailError: "Please add at least One email!" });
            return;
        }
    };

    handleSkipButton = () => {
        this.setState({ invited: false });
        this.props.hide();
    };
    closeModal = () => {
        this.setState({ invited: false });
    };
    newDirectory = (name, emailList, messageBody) => {
        this.props.createDirectory(name, emailList, messageBody);
        this.clearData();
        this.setState({ name: "", chipsArray: [] });
    };
    newUser = (directoryId, messageBody) => {
        if (this.searchText !== "") {
            let currentUser = sessionStorage.getItem("user");
            if (this.searchText === currentUser) {
                return;
            }
            this.createChip(this.searchText.trim());
        }
        setTimeout(() => {
            this.props.inviteUser(
                this.state.chipsArray,
                directoryId,
                messageBody
            );
            this.setState({ chipsArray: [] });
            this.props.hide();
            this.clearData();
        }, 500);
    };
    closeInviteModal = () => {
        this.clearData();
        this.props.hide();
    };
    putTextInChips = (text) => {
        let index = 0;
        index = this.chipsArray.length;
        this.chipsArray.push({
            key: text + "_" + index,
            label: text,
        });
        this.setState({ chipsArray: this.chipsArray });
    };
    handleDeleteChipItem = (chipToDelete, index) => {
        let items = this.chipsArray;
        items.splice(index, 1);
        this.setState({ chipsArray: items });
    };
    setFromText = (value, id, event) => {
        switch (id) {
            case "inviteMailTextBox":
                this.searchText = value;
                this.validateEmail(value.trim());
                break;
            case "txt_Invite_messageBody":
                this.messageBody = value;
                if (this.state.messageError && value)
                    this.setState({ messageError: "" });
                break;
            default:
                break;
        }
    };

    validateEmail = (mail) => {
        let email = mail.trim();
        if (email !== "") {
            let isValidEmail = ValidateEmail(email);
            if (isValidEmail) {
                this.setState({
                    emailError: "",
                });
                return true;
            } else {
                this.setState({
                    emailError: "Please Enter valid Email",
                });
                return false;
            }
        } else {
            this.setState({
                emailError: "",
            });
            return false;
        }
    };
    getChipsText = () => {
        let str = [];
        this.chipsArray.forEach((element, index) => {
            str.push(element.label.toString());
        });
        return str;
    };
    isValidEmail = (mail) => {
        let email = mail.trim();
        if (email !== "") {
            let isValidEmail = ValidateEmail(email);
            if (isValidEmail) {
                return isValidEmail;
            } else {
                this.setState({
                    emailError: "Please Enter valid Email",
                });
                return isValidEmail;
            }
        } else {
            this.setState({
                emailError: "",
            });
            return false;
        }
    };
    onChange = (value) => {
        if (value.length === 1 && value === " ")
            this.setState({ name: value.trim() });
        else this.setState({ name: value });
    };
    handleKeyDown = (value, key) => {
        let currentUser = sessionStorage.getItem("user");
        if (value === currentUser) {
            return;
        }
        if (value.trim() !== "") {
            this.createChip(value.trim());
        }
    };
    createChip = (value) => {
        let isValidEmail = this.isValidEmail(value.trim());
        if (!isValidEmail) {
            return false;
        }
        if (value !== "" && isValidEmail) {
            let flag = false;
            this.chipsArray.forEach((item) => {
                if (item.label === this.searchText) {
                    flag = true;
                }
            });
            if (flag === false) {
                this.putTextInChips(this.searchText);
                this.searchText = "";
                this.setState({ emailError: "" });
            }
            document.getElementById("inviteMailTextBox").value = "";
            return true;
        }
    };

    renderClassForChips = () => {
        if (this.chipsArray.length <= 3) {
            return "col-" + this.chipsArray.length * 3;
        } else {
            return "col-9";
        }
    };
    removeUser = () => {
        this.props.removeDirectoryUser();
        this.props.hide();
    };
    renderChipsInputUI = () => {
        return (
            <div className="w-100 m-0 doCenter" style={{ flexWrap: "wrap" }}>
                <div className="invite_div_box">
                    <div className="row w-100 m-0" style={{ display: "block" }}>
                        <ChipsView
                            items={this.state.chipsArray}
                            ondelete={this.handleDeleteChipItem}
                        ></ChipsView>

                        <TextBox
                            divClass={"rippleSerachDiv"}
                            className="userSerachInputBox invite-search"
                            id="inviteMailTextBox"
                            clearAfterChange={true}
                            type="text"
                            label={
                                this.state.chipsArray.length === 0
                                    ? "Enter e-mail"
                                    : "Enter another e-mail"
                            }
                            placeholder={true}
                            onkeydown={this.handleKeyDown}
                            handleSpecialKeys={this.handleKeyDown}
                            onchange={this.setFromText}
                        ></TextBox>
                    </div>
                </div>
                {this.state.emailError && (
                    <>
                        <small style={{ color: "red" }}>
                            {this.state.emailError}
                        </small>
                        <br />
                    </>
                )}
                <br />
            </div>
        );
    };

    renderTextArea = () => {
        return (
            <TextArea
                divClass="w-100 doCenter"
                className="invite_msg_box "
                id="txt_Invite_messageBody"
                rows="5"
                cols="25"
                label={"Write a custom message..."}
                onchange={this.setFromText}
            ></TextArea>
        );
    };
    renderModalBody = () => {
        return (
            <div className="row doCenter" style={{ flexDirection: "column" }}>
                <div className="row m-0 doCenter mb-4">
                    <span
                        className="invite_modal_title"
                        style={
                            this.props.heading ===
                            "Invite Contacts to Directory"
                                ? {
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      marginTop: "-37px",
                                      marginRight: "42px",
                                  }
                                : this.props.heading === "Remove Contacts"
                                ? {
                                      marginTop: "-37px",
                                      marginLeft: "-235px",
                                      fontWeight: "bold",
                                  }
                                : {
                                      marginLeft: "-225px",
                                      fontWeight: "bold",
                                  }
                        }
                    >
                        {this.props.heading}
                    </span>
                </div>
                {this.props.heading === "Remove Contacts" ? (
                    <>
                        <div className="container-remove">
                            Are you sure you want to remove this contact?
                        </div>
                        <div
                            style={{
                                marginTop: "15px",
                                display: "flex",
                                marginLeft: "210px",
                            }}
                        >
                            <button
                                type="button"
                                id="btn_remove"
                                className="btn removeBtn mt-3"
                                style={{ fontWeight: "400", maxWidth: "20rem" }}
                                name="btn_signup"
                                onClick={this.props.hide}
                            >
                                CANCEL
                            </button>
                            <button
                                type="submit"
                                id="btn_invite_submit"
                                className="btn btn-primary submitBtn mt-3"
                                style={{
                                    height: " 41px",
                                    marginLeft: "25px",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    maxWidth: "7rem",
                                    marginBottom: "0px",
                                    fontFamily: "Montserrat",
                                }}
                                name="btn_signup"
                                onClick={this.removeUser}
                            >
                                Remove
                            </button>
                        </div>
                    </>
                ) : (
                    <div
                        style={
                            this.props.heading ===
                            "Invite Contacts to Directory"
                                ? { width: "90%" }
                                : {}
                        }
                    >
                        {this.props.heading ===
                        "Invite Contacts to Directory" ? null : (
                            <div
                                style={{
                                    width: "92%",
                                    margin: "auto",
                                    paddingBottom: "17px",
                                }}
                            >
                                <TextBox
                                    title="Directory Name"
                                    id="directory_name"
                                    className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft "
                                    type="text"
                                    label="Directory Name"
                                    value={this.state.name}
                                    onchange={this.onChange}
                                    formControl="Off"
                                    doCenter={true}
                                ></TextBox>
                            </div>
                        )}

                        {this.props.heading ===
                        "Invite Contacts to Directory" ? null : (
                            <span className="invite_modal_title">
                                Invite People
                            </span>
                        )}
                        {this.renderChipsInputUI()}
                        {this.renderTextArea()}
                        {this.state.messageError && (
                            <>
                                <small style={{ color: "red" }}>
                                    {this.state.messageError}
                                </small>
                                <br />
                            </>
                        )}
                        <br />
                    </div>
                )}
                {this.props.heading !== "Remove Contacts" ? (
                    <button
                        disabled={
                            this.state.emailError !== "" ||
                            (!this.state.chipsArray.length &&
                                this.searchText === "")
                                ? true
                                : false
                        }
                        type="submit"
                        id="btn_invite_submit"
                        className="btn btn-primary submitBtn mt-3"
                        style={{
                            fontWeight: "400",
                            maxWidth: "14rem",
                            marginLeft: "170px",
                            textTransform: "none",
                        }}
                        name="btn_signup"
                        onClick={() => {
                            if (this.props.heading === "Create Directory") {
                                this.newDirectory(
                                    this.state.name,
                                    this.state.chipsArray,
                                    this.messageBody
                                );
                            } else {
                                this.newUser(undefined, this.messageBody);
                            }
                        }}
                    >
                        {this.props.heading === "Create Directory"
                            ? "Create Directory"
                            : "Invite Contacts"}
                    </button>
                ) : null}
            </div>
        );
    };

    renderModal = () => {
        return (
            <ModalView
                show={this.props.show}
                onHide={this.closeInviteModal}
                footer={"false"}
                size="md"
            >
                {this.renderModalBody()}
            </ModalView>
        );
    };
    renderAlertModel = () => {
        return (
            <ModalView
                show={this.state.invited}
                onHide={this.closeModal}
                footer={"false"}
                size="sm"
            >
                <div
                    className="w-100 doCenter"
                    style={{ flexDirection: "column" }}
                >
                    <div className="row m-0 w-100 doCenter">
                        <span className="modalTitle">Invite Sent</span>
                    </div>
                    <button
                        type="submit"
                        id="btn_invite_closeInviteSuccessModal"
                        className="btn btn-primary submitBtn"
                        style={{
                            fontWeight: "400",
                            maxWidth: "7rem",
                            height: "3rem",
                            marginTop: "2rem",
                        }}
                        onClick={this.handleSkipButton}
                    >
                        DONE
                    </button>
                </div>
            </ModalView>
        );
    };
    render() {
        return (
            <div>
                {this.renderModal()}
                {this.renderAlertModel()}
                <Loader show={this.state.showLoader} />
                <AlertDialog
                    open={this.state.showAlertDialog}
                    message={this.state.alertDialogMessage}
                    title={this.state.alertDialogTitle}
                    isAlert={this.state.isAlert}
                    setOpen={this.changeOpenDialogFlag}
                ></AlertDialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        chatList: state.chatList,
    };
};
export default connect(mapStateToProps)(CreateDirectory);
