import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CreateDirectory from "./CreateDirectory";
import Loader from "../../../../stylized/Loader";
import "./directory.css";
import http from "../../../../../bae-client/HTTP";
import DirectoryTable from "./DirectoryTable";
import { fetchUserListData, fetchDirectoryListData } from "./DirectoryActions";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "../../../../stylized/AlertDialog";
import CustomSnackbar from "../../../../stylized/CustomSnackbar/CustomSnackbar";

const Directory = () => {
    const dispatch = useDispatch();
    const [allUsers, setAllUsers] = useState([]);
    const [alertDialogContent, setAlertDialogContent] = useState({
        title: "",
        message: "",
        isAlert: false,
        messageId: "",
        buttonName: "",
        buttonId: "",
    });
    const [alertDialogShow, setAlertDialogShow] = useState(false);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [showModel, setShowModel] = useState(false);
    const [showRemoveModel, setShowRemoveModel] = useState(false);
    const [showInviteModel, setShowInviteModel] = useState(false);
    const [directoryInviteData, setDirectoryInviteData] = useState([]);
    const [deleteUserData, setDeleteUserData] = useState(null);
    const [showLoader, setshowLoader] = useState(false);
    const [directoryData, setDirectoryData] = useState([]); // State to store fetched data
    const directoryList = useSelector((state) => state.directoryList);
    const directoryUsersList = useSelector((state) => state.directoryUsersList);
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const [inviteUserToAdd, setInviteUserToAdd] = useState(null);

    useEffect(() => {
        dispatch(fetchDirectoryListData());
    }, []);
    // useEffect(() => {
    //     // let userId = 2; //mock url endpoint userid
    //     try {
    //         const params = new URLSearchParams(window.location.search);
    //         const encodedString = params.get("query");
    //         if (encodedString) {
    //             const decodedString = atob(encodedString);
    //             const twoValues = decodedString.split("&");
    //             if (twoValues.length === 2) {
    //                 const invitee = twoValues[0].split("=").pop();
    //                 const userId = twoValues[1].split("=").pop();
    //                 // const userId = parseInt(window.location.search.slice(1));
    //                 if (invitee && userId == sessionStorage.getItem("userId")) {
    //                     http.sendRestRequest("user/?active=true")
    //                         .then((response) => {
    //                             setAllUsers(response);
    //                             let users = response;
    //                             let activeuser = users.find((user) => {
    //                                 if (user.userId == invitee) return user;
    //                             });
    //                             let userToAdd;
    //                             if (activeuser) {
    //                                 userToAdd = directoryUsersList.find(
    //                                     (user) => {
    //                                         if (
    //                                             user.emailAddress ===
    //                                             activeuser.userEmail
    //                                         ) {
    //                                             return true;
    //                                         }
    //                                     }
    //                                 );
    //                                 if (!userToAdd) {
    //                                     setInviteUserToAdd(activeuser);
    //                                     setAlertDialogShow(true);
    //                                     setAlertDialogContent({
    //                                         // title: "Delete Workspace",
    //                                         message: `${activeuser.firstName} ${activeuser.lastName} has added you as a contact to their Directory. Click Add Contact button below to add them to your Directory`,
    //                                         isAlert: true,
    //                                         // messageId: "workspaceDeleteMsg",
    //                                         buttonName: "Add Contact",
    //                                         // buttonId: "workspaceDeleteBtn",
    //                                     });
    //                                 }
    //                             }
    //                         })
    //                         .catch((erro) => {
    //                             console.log(erro.error);
    //                         });
    //                 }
    //             }
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }, [directoryUsersList]);
    useEffect(() => {
        if (directoryList && directoryList.length) {
            setDirectoryData(directoryList);
            console.log("1");
            dispatch(
                fetchUserListData(
                    directoryList[directoryList.length - 1].directoryId
                )
            );
        }
    }, []);

    useEffect(() => {
        if (directoryUsersList) {
            setDirectoryInviteData(directoryUsersList);
        }
    }, [directoryUsersList]);
    const handleConfirmationDialog = (response) => {
        setAlertDialogContent({
            title: "",
            message: "",
            isAlert: false,
        });
        if (response === "YES") {
            // setShowInviteModel(true);
            if (inviteUserToAdd) {
                const invitedUser = {
                    emailAddress: inviteUserToAdd.userEmail.trim(),
                    userId: inviteUserToAdd.userId,
                    userType: "Internal",
                    access: "Read/Write",
                };
                let dirId = directoryData[directoryData.length - 1].directoryId;
                setshowLoader(true);
                http.sendRestRequest(
                    `directory/user?directoryId=${dirId}&invitationMessage=${"Please join!"}`,
                    "POST",
                    invitedUser
                )
                    .then(() => {
                        dispatch(fetchUserListData(dirId));
                        setShowModel(false);
                        setShowInviteModel(false);
                        setshowLoader(false);
                    })
                    .catch((error) => {
                        setShowAlertDialog(true);
                        setAlertDialogMessage(error.error);

                        setshowLoader(false);
                    });
            }
        }
    };
    const updateDirectory = () => {
        http.sendRestRequest(`directory`, "PUT")
            .then(() => {
                dispatch(fetchDirectoryListData());
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const deleteDirectoryUser = () => {
        http.sendRestRequest(
            `directory/user?directoryId=${
                directoryData[directoryData.length - 1].directoryId
            }&directoryUserRegistryId=${
                deleteUserData.directoryUserRegistryId
            }`,
            "DELETE"
        )
            .then(() => {
                dispatch(
                    fetchUserListData(
                        directoryData[directoryData.length - 1].directoryId
                    )
                );
                setSnackbarMessage("User Removed Successfully!");
                setShowSnackbar(true);
                setShowModel(false);
                setShowRemoveModel(false);
            })
            .catch((error) => {
                console.error("Error adding user to directory:", error);
            });
    };
    const inviteUser = (emailList, directoryId, messageBody) => {
        let message = encodeURIComponent(messageBody);
        emailList.forEach((userEmail) => {
            const invitedUser = {
                emailAddress: userEmail.label.trim(),
                userId: -1,
                userType: "Internal",
                access: "Read/Write",
            };
            let dirId = directoryId
                ? directoryId
                : directoryData[directoryData.length - 1].directoryId;
            setshowLoader(true);
            http.sendRestRequest(
                `directory/user?directoryId=${dirId}&invitationMessage=${message}`,
                "POST",
                invitedUser
            )
                .then(() => {
                    dispatch(fetchUserListData(dirId));
                    setShowModel(false);
                    setShowInviteModel(false);
                    setshowLoader(false);
                })
                .catch((error) => {
                    setShowAlertDialog(true);
                    setAlertDialogMessage(error.error);

                    setshowLoader(false);
                });
        });
    };
    const createDirectory = (directoryName, emailList, messageBody) => {
        setshowLoader(true);
        let url = "directory?directoryName=" + directoryName;
        let method = "POST";
        http.sendRestRequest(url, method)
            .then((response) => {
                dispatch(fetchDirectoryListData());
                if (emailList.length) {
                    inviteUser(emailList, response.directoryId, messageBody);
                } else {
                    setShowModel(false);
                    setShowInviteModel(false);
                    setshowLoader(false);
                }
            })
            .catch((error) => {
                console.error("Error creating directory:", error);
                setshowLoader(false);
            });
    };

    const fetchDirectoryData = () => {
        setshowLoader(true);
        let url = `directory/list`;
        http.sendRestRequest(url, "GET")
            .then((response) => {
                setDirectoryData(response);
                setshowLoader(false);
                fetchInivteUserData(response[response.length - 1].directoryId);
            })
            .catch((error) => {
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
                setshowLoader(false);
            });
    };
    const fetchInivteUserData = (id) => {
        setshowLoader(true);
        let url = `directory/user/list?directoryId=${id}`;
        http.sendRestRequest(url, "GET")
            .then((response) => {
                setDirectoryInviteData(response);
                setshowLoader(false);
            })
            .catch((error) => {
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
                setshowLoader(false);
            });
    };

    const openInviteModal = () => {
        setShowInviteModel(true);
    };
    const openRemoveModal = (row) => {
        setShowRemoveModel(true);
        setDeleteUserData(row);
    };

    const openModal = () => {
        setShowModel(true);
    };

    const closeInviteModal = () => {
        setShowModel(false);
        setShowInviteModel(false);
        setShowRemoveModel(false);
    };

    const renderCreateModal = () => {
        return (
            <CreateDirectory
                heading={"Create Directory"}
                show={showModel}
                openModal={openModal}
                hide={closeInviteModal}
                createDirectory={createDirectory}
            ></CreateDirectory>
        );
    };
    const hideAlertView = () => {
        setShowAlertDialog(false);
    };
    const renderInviteModal = () => {
        return (
            <CreateDirectory
                heading={"Invite Contacts to Directory"}
                show={showInviteModel}
                updateDirectory={updateDirectory}
                hide={closeInviteModal}
                inviteUser={inviteUser}
            ></CreateDirectory>
        );
    };
    const renderRemoveUserModal = () => {
        return (
            <CreateDirectory
                heading={"Remove Contacts"}
                show={showRemoveModel}
                removeDirectoryUser={deleteDirectoryUser}
                hide={closeInviteModal}
            ></CreateDirectory>
        );
    };

    return (
        <div>
            {directoryData.length ? (
                <div
                    className="defaultScroller"
                    style={{ padding: "12px 30px" }}
                >
                    <DirectoryTable
                        openRemoveModal={openRemoveModal}
                        handleClose={closeInviteModal}
                        data={directoryInviteData}
                        directoryName={
                            directoryData[directoryData.length - 1]
                                .directoryName
                        }
                        openInviteModal={openInviteModal}
                    />
                </div>
            ) : (
                <div className="container_directory">
                    <h1>Create a directory and invite contacts</h1>
                    <Button
                        variant="contained"
                        id="contain-btn"
                        onClick={openModal}
                        style={{ textTransform: "none" }}
                    >
                        Create Directory
                    </Button>

                    {/* <Button
                        variant="contained"
                        className="contain-btn"
                        onClick={openRemoveModal}
                    >
                        Remove
                    </Button> */}
                </div>
            )}
            {renderCreateModal()}
            {renderRemoveUserModal()}
            {renderInviteModal()}
            <CustomSnackbar
                open={showSnackbar}
                setOpen={setShowSnackbar}
                alertMessage={snackbarMessage}
            ></CustomSnackbar>
            <Loader show={showLoader}></Loader>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                setOpen={hideAlertView}
                isAlert={false}
            ></AlertDialog>
            <AlertDialog
                open={alertDialogShow}
                setOpen={setAlertDialogShow}
                title={alertDialogContent.title}
                message={alertDialogContent.message}
                isAlert={alertDialogContent.isAlert}
                handleConfirmationDialog={handleConfirmationDialog}
                messageId={alertDialogContent.messageId}
                buttonId={alertDialogContent.buttonId}
                buttonName={alertDialogContent.buttonName}
            ></AlertDialog>
        </div>
    );
};

export default Directory;
