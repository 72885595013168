import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import {
    setActiveRipple,
    setChatRippleMsg,
    setActivePage,
} from "../../../actions";
import { format } from "date-fns";
import {
    setShowDrawer,
    setRippleDocumentResponse,
    setRippleKeywordSearched,
    setTimelineDocumentList,
    setChipArray,
    setSelectedWorkspaceIndex,
    setSelectedSpaceIndex,
    setSelectedFolderIndex,
    setSelectedPeriod,
    setSpaceArray,
    setRippleNameStr,
    setRippleId,
    setDeleteRippleId,
    setValue,
    setSelectedWorkspaceArray,
    setSelectedSpaceArray,
    setSelectedDateArray,
    setSelectedFolderArray,
} from "../../../actions/Rippleaction";
import {
    fetchHierarchyResponse,
    resetHierarchy,
} from "../../../actions/HierarchyActions";
import { setRippleFilterAction } from "../../../services/services/app";
import { setRippleResult } from "../../../actions/RippleActions";
import http from "../../../bae-client/HTTP";
import RippleHandler from "../../../bae-client/Ripple";
import RippleManager from "../../../bae-client/RippleManager";
import { validateName } from "../../../bae-client/Validations";
import AlertDialog from "../../stylized/AlertDialog";
import Button from "../../stylized/Button";
import CheckboxView from "../../stylized/CheckboxView";
import ChipsView from "../../stylized/ChipsView";
import DocViwer from "../../stylized/DocViwer";
import DrawerView from "../../stylized/DrawerView";
import InlineRow from "../../stylized/InlineRow";
import Loader from "../../stylized/Loader";
import ModalView from "../../stylized/ModalView";
import DocumentViewerModal from "../../stylized/DocumentViewerModal";
import PopoverViewCustom from "../../stylized/PopoverViewCustom";
import RippleLogTableView from "../../stylized/RippleLogTableView";
import TextBox from "../../stylized/TextBox";
import VerticalTimelineView from "./ContentPane/TimeLineView/VerticalTimelineView";
import RippleRenderer from "./RippleRenderer";
import "./RippleView.css";
import TitleBar from "./TiltleBar";
import * as Mixpanel from "../../../mix-panel/Mixpanel";
import RippleFilters from "./RippleFilters";

class Ripple extends Component {
    // from now
    constructor(props) {
        super(props);
        this.isReadOnlyUser =
            sessionStorage.getItem("isReadOnlyUser") === "true";
        this.basePathName = window.runtimeConfig.instanceName;

        this.state = {
            selectedIndex: -1,
            isWorkspaceSelectAllChecked: false,
            isSpaceSelectAllChecked: false,
            isFolderSelectAllChecked: false,
            getNode: "",
            getRippleNode: "",
            rippleNameStr: "Name Ripple",
            showWorkspaceDropdown: false,
            showSpaceDropdown: false,
            popoverTarget: null,
            showLoder: false,
            showWSHLoader: false,
            dataLoded: false,
            rippleData: null,
            showRippleNameModal: false,
            isDesableNameBtn: false,
            showDocModal: false,
            currentShowFileLocation: "",
            showWorkspacesAccordian: true,
            showSpaceAccordian: false,
            showFolderAccordian: false,
            currentShowFileName: "",
            showViewModal: false,
            redirectToRipplelog: false,
            selectedStartDate: "",
            selectedEndDate: "",
            tabKey: "Filter",
            workspaceArray: [],
            timelineExpanded: true,
            selectedDocument: null,
            showDocumentModal: false,
            showAlertModal: false,
            rippleLogData: [],
            emailError: "",
            error: "",
            errRipple: "",
            chipsArray: [],
            dateErr: false,
            showDrawer: false,
            selectedWorkspacesArray: [],
            selectedSpacesArray: [],
            selectedFoldersArray: [],
            selectedTimeFilter: [],
            selectedTimeFilterLocal: {
                startDate: "",
                endDate: "",
                selectedTimeType: "",
            },
        };

        this.timeLineContainer = document.getElementById("myTimeLine");
        this.resetDate = React.createRef();
        this.searchText = "";
        this.ripple = "";
        this.rippleName = "";
        this.rippleId = -1;
        this.chipsArray = [];
        this.spaceListArray = [];
        this.spaceListArray[0] = [];
        this.workspaceindexArray = [];
        this.SpaceindexArray = [];
        this.spaceTree = [];
        this.selectedWorkspaceIndex = [];
        this.RippleLogManager = null;
        this.RippleRegistryManager = null;
        this.rippleManager = null;
        this.idToRippleName = null;
        this.documentArray = [];
        this.ripple = null;
        this.fetcher = null;
        this.rippleDataObject = null;
        this.currentShowFileName = "";
        this.rippleHandler = new RippleHandler();
        this.selectedWorkspaceIDS = [];
        this.selectedSpaceIDS = [];
        this.selectedFolderIDS = [];
        this.spaceFolderList = [];
        this.selectedFolderIndexArray = [];
        this.folderListIdtoName = new Map();
        this.folderIdsBySpaceId = new Map();
        this.isDateChanged = false;
        this.selectedDateFilterType = "";
        this.workspacePersistenceTblId = null;
        this.spaceRegistryTblId = null;
        this.workspaceRegistryTblId = null;
        this.spacePersistenceTblId = null;
        this.hierarchyTree = null;
    }
    componentDidMount() {
        Mixpanel.callPageViewEvent("Ripple");

        this.headCells = [
            {
                id: "Action",
                numeric: true,
                disablePadding: true,
                width: 60,
                label: "",
                align: "center",
            },
            {
                id: "Type",
                numeric: true,
                disablePadding: true,
                width: 60,
                label: "Type",
                align: "center",
            },
            {
                id: "name",
                numeric: false,
                disablePadding: true,
                width: 140,
                label: "Name",
                align: "center",
            },
            {
                id: "space",
                numeric: true,
                disablePadding: true,
                width: 100,
                label: "Location",
                align: "center",
            },
            {
                id: "createdDate",
                numeric: true,
                disablePadding: true,
                width: 80,
                label: "createdDate",
                align: "center",
            },
            {
                id: "createdDate",
                numeric: true,
                disablePadding: true,
                width: 90,
                label: "Added To Space",
                align: "center",
            },
        ];
        this.selectChkObj = [
            {
                text: "Select All ",
                value: "Select All",
                id: 0,
            },
        ];
        if (this.props.rippleDeleteId.length) {
            this.clearRipple();
            this.setState({
                chipsArray: [],
            });
            this.props.setDeleteRippleId("");
        } else {
            this.setState({
                showLoder: true,
                chipsArray: this.props.chipsArray,
                rippleNameStr: this.props.rippleNameStr,
            });
            this.generateRippleLogRowData()
                .then((resp) => {
                    if (
                        this.props.activeRipple !== undefined &&
                        this.props.activeRipple !== null &&
                        this.props.activeRipple.rippleId !== undefined
                    ) {
                        this.loadExistingRippleData();
                    } else if (
                        this.props.activeRipple &&
                        !Array.isArray(this.props.activeRipple) &&
                        !this.props.activeRipple.rippleId
                    ) {
                        this.openSharedRipple();
                    } else if (this.props.chipsArray.length) {
                        if (this.props.selectedWorkspaceIndex !== null) {
                            let selectedWorkspacesArray = [];
                            let selectedSpacesArray = [];
                            let selectedFoldersArray = [];
                            let spaceIndex = 0;
                            let folderIndex = 0;

                            this.hierarchyTree.forEach((item, i) => {
                                if (
                                    this.props.selectedWorkspaceIndex.includes(
                                        i
                                    )
                                ) {
                                    this.selectedWorkspaceIDS.push(
                                        item.workspaceId
                                    );
                                    selectedWorkspacesArray.push({
                                        text: item.workspaceName,
                                        value: item.workspaceName,
                                        id: item.workspaceId,
                                    });

                                    item.spaceList.forEach((space) => {
                                        if (
                                            this.props.selectedSpaceIndex &&
                                            this.props.selectedSpaceIndex.includes(
                                                spaceIndex
                                            )
                                        ) {
                                            this.selectedSpaceIDS.push(
                                                space.spaceId
                                            );
                                            selectedSpacesArray.push({
                                                text: space.spaceName,
                                                value: space.spaceName,
                                                id: space.spaceId,
                                            });
                                            space.folders.forEach((folder) => {
                                                if (
                                                    this.props
                                                        .selectedFolderIndex &&
                                                    this.props.selectedFolderIndex.includes(
                                                        folderIndex
                                                    )
                                                ) {
                                                    selectedFoldersArray.push({
                                                        text: folder.folderName,
                                                        value: folder.folderName,
                                                        id: folder.folderId,
                                                    });
                                                }
                                                folderIndex++;
                                            });
                                        }
                                        spaceIndex++;
                                    });
                                }
                            });
                            this.setState({
                                selectedWorkspacesArray:
                                    selectedWorkspacesArray,
                                selectedSpacesArray: selectedSpacesArray,
                                selectedFoldersArray: selectedFoldersArray,
                            });
                        }
                        this.setState({
                            selectedTimeFilterLocal:
                                this.props.selectedDateArray,
                            selectedTimeFilter: [
                                this.props.selectedDateArray.selectedTimeType,
                            ],
                        });
                    } else {
                        this.setState({ showLoder: false });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ showLoder: false });
                });
        }
    }
    componentWillUnmount = () => {
        this.props.setActiveRipple([]);
    };
    componentDidUpdate = () => {
        if (this.state.redirectToRipplelog) {
            this.props.setActivePage("Ripple Log");
        }
    };
    generateRippleLogRowData = () => {
        return new Promise((resolve, reject) => {
            this.setState({ showLoder: true });
            this.rippleManager = new RippleManager();
            this.rippleManager
                .loadRippleLog()
                .then((resp) => {
                    let rippleLogNameArray = [];
                    if (resp.length > 0) {
                        resp.forEach((rippleObj) => {
                            rippleLogNameArray.push(
                                rippleObj.rippleName.trim().toLowerCase()
                            );
                        });
                    }
                    this.setState({
                        rippleLogData: rippleLogNameArray,
                        showLoder: false,
                    });
                    resolve(1);
                })
                .catch((err) => {
                    this.showAlertView("", err.error);
                    this.setState({ showLoder: false });
                    reject(0);
                });
        });
    };

    getDaysbydates = (dateString) => {
        if (dateString) {
            const dateStr1 = dateString.split("~")[0];
            const dateStr2 = dateString.split("~")[1];
            const date1 = new Date(dateStr1);
            const date2 = new Date(dateStr2);
            const timeDifference = date2 - date1;
            const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
            let startDate;
            let endDate;

            if (dateString.split("~")[0] !== "") {
                startDate = dateString.split("~")[0].split(" ");
                endDate = dateString.split("~")[1].split(" ");
            } else {
                startDate = "";
                endDate = "";
            }
            let timeFilter;
            if (dayDifference === 7) {
                timeFilter = ["Last 7 days"];
            } else if (dayDifference === 14) {
                timeFilter = ["Last 14 days"];
            } else if (dayDifference === 30) {
                timeFilter = ["Last 30 days"];
            } else if (dayDifference === 90) {
                timeFilter = ["Last 90 days"];
            } else if (dayDifference === 365) {
                timeFilter = ["Last Year"];
            } else {
                timeFilter = [startDate, endDate];
            }
            return timeFilter;
        }
    };

    processData = (filterObject) => {
        this.props.setRippleNameStr(this.props.activeRipple.rippleName);
        let searchCriteria = this.props.activeRipple.searchCriteria;
        let chipsArray = [];
        if (searchCriteria.includes("&")) {
            chipsArray = searchCriteria.split("&");
        } else {
            chipsArray.push(searchCriteria);
        }

        this.props.setRippleNameStr(this.props.activeRipple.rippleName);

        if (chipsArray !== null && chipsArray.length > 0) {
            chipsArray.forEach((text) => {
                this.putTextInChips(text);
            });
            if (!filterObject) this.onClickRipple();
            else this.onClickRipple(null, filterObject);
        }
    };

    openSharedRipple() {
        this.setState({ rippleNameStr: this.props.activeRipple.rippleName });
        let workspaces,
            spaces = [],
            folders = [],
            dates = [];
        const sharedWS = this.props.activeRipple.filterCriteriaWorkspaces;
        const sharedSP = this.props.activeRipple.filterCriteriaSpaces;
        const sharedF = this.props.activeRipple.filterCriteriaFolders;
        const sharedD = this.props.activeRipple.filterCriteriaDates;
        if (!sharedWS) this.processData();
        else {
            workspaces = this.props.workspaceList
                .filter((ws) => sharedWS.includes(ws.workspaceName))
                .map((ws) => parseInt(ws.workspaceId));
            if (!workspaces.length) this.processData();
            else {
                let url = `workspace/selectedWorkspaces/spaceList?workspaceIds=${workspaces.toString()}`;
                http.sendRestRequest(url).then((spaceList) => {
                    spaces = spaceList
                        .filter((sp) => sharedSP.includes(sp.spaceName))
                        .map((sp) => parseInt(sp.spaceId));
                    const filterObject = {
                        workspaces: workspaces.map((ws) => parseInt(ws)),
                        spaces: spaces.map((sp) => parseInt(sp)),
                        folders: folders,
                        timeFilter: this.getDaysbydates(sharedD) || [],
                    };
                    this.processData(filterObject);
                });
            }
        }
    }
    loadExistingRippleData = () => {
        let searchCriteria = this.rippleDataObject.searchCriteria;
        let workspaces,
            space,
            folders,
            dates = [];
        workspaces = this.rippleDataObject.filterCriteriaWorkspacesthis
            ? this.rippleDataObject.filterCriteriaWorkspaces.split(",")
            : [];
        space = this.rippleDataObject.filterCriteriaSpaces
            ? this.rippleDataObject.filterCriteriaSpaces.split(",")
            : [];
        folders = this.rippleDataObject.filterCriteriaFolders
            ? this.rippleDataObject.filterCriteriaFolders.split(",")
            : [];
        dates = this.getDaysbydates(this.rippleDataObject.filterCriteriaDates);
        const filterObject = {
            workspaces: workspaces.map((ws) => parseInt(ws)),
            spaces: space.map((sp) => parseInt(sp)),
            folders: folders,
            timeFilter: dates,
        };
        let chipsArray = [];
        if (searchCriteria.includes("&")) {
            chipsArray = searchCriteria.split("&");
        } else {
            chipsArray.push(searchCriteria);
        }
        this.setState({ rippleNameStr: this.rippleDataObject.rippleName });
        this.rippleId = this.rippleDataObject.rippleId;
        if (this.props.chipsArray.length === 0) {
            if (chipsArray !== null && chipsArray.length > 0) {
                chipsArray.forEach((text) => {
                    this.putTextInChips(text);
                });
                this.setState({ showLoder: false });
                this.onClickRipple(null, filterObject);
            }
        }
    };
    getSlectedWorkspaceIndexById = () => {
        let workspaceArray = [];
        let spaceList = [];
        this.selectedWorkspaceIndex = [];
        this.spaceListArray = [];
        this.spaceListArray[0] = [];
        let workspaceTree = this.hierarchyTree;
        let wsName = "";
        if (workspaceTree !== null && workspaceTree !== undefined) {
            workspaceTree.map((item, i) => {
                this.selectedWorkspaceIDS.forEach((id) => {
                    if (item.workspaceId === id) {
                        spaceList = item.spaceList;
                        wsName = item.workspaceName;
                        workspaceArray.push({
                            text: wsName,
                            value: wsName,
                            id: id,
                        });
                        this.selectedWorkspaceIndex.push(i);
                        if (spaceList !== null && spaceList.length > 0) {
                            spaceList.map((item, i) => {
                                let obj = {};
                                obj["text"] = item.spaceName;
                                obj["value"] = item.spaceName;
                                obj["id"] = item.spaceId;
                                this.spaceListArray[0].push(obj);
                                if (!this.spaceListArray.includes(wsName)) {
                                    this.spaceListArray.push(wsName);
                                }
                            });
                        }
                    }
                });
            });
        }
        this.props.setSpaceArray(this.spaceListArray[0]);
        this.setState({ selectedWorkspacesArray: workspaceArray });
        this.props.setSelectedWorkspaceArray(workspaceArray);
        this.props.setSelectedWorkspaceIndex(this.selectedWorkspaceIndex);
    };
    selectAllSpacesById = () => {
        let spaceIdArray = [];
        this.SpaceindexArray = [];
        let spaceList = this.props.spaceArray;
        for (let index = 0; index < spaceList.length; index++) {
            this.selectedSpaceIDS.forEach((spaceId) => {
                if (spaceId === spaceList[index].id) {
                    spaceIdArray.push(spaceList[index]);
                    this.SpaceindexArray.push(index);
                }
            });
        }
        this.setState({ selectedSpacesArray: spaceIdArray });
        this.props.setSelectedSpaceArray(spaceIdArray);
        this.props.setSelectedSpaceIndex(this.SpaceindexArray);
    };
    selectFoldersById = (selectedFolderList) => {
        let folderIdArray = [];
        let temp = [];
        this.spaceFolderList = [];
        this.selectedFolderIndexArray = [];
        this.selectedSpaceIDS.forEach((spaceId, index) => {
            let folderList = this.spaceManager.getFolderListBySpaceId(spaceId);
            let tempIds = [];
            if (folderList !== undefined) {
                folderList.forEach((folder, index) => {
                    tempIds.push(folder.folderId);
                });
                this.folderIdsBySpaceId.set(spaceId, tempIds);
            }
            if (folderList !== undefined) temp = temp.concat(folderList);
        });
        if (temp.length > 0) {
            temp.forEach((folder) => {
                this.spaceFolderList.push({
                    text: folder.folderName,
                    value: folder.folderName,
                    id: folder.folderId,
                });
                this.folderListIdtoName.set(folder.folderId, folder.folderName);
            });
        }
        this.spaceFolderList.forEach((element, index) => {
            selectedFolderList.forEach((id) => {
                if (parseInt(id) === element.id) {
                    folderIdArray.push(element);
                    let folderRowId = element.id;
                    this.selectedFolderIDS.push(folderRowId);
                    this.selectedFolderIndexArray.push(index);
                }
            });
        });
        this.setState({ selectedFoldersArray: folderIdArray });
        this.props.setSelectedFolderArray(folderIdArray);
        this.props.setSelectedFolderIndex(this.selectedFolderIndexArray);
    };
    handleKeyDown = (value, key) => {
        if (value.trim() !== "") {
            this.createChip(value.trim());
        }
    };
    createChip = (value) => {
        if (value.trim() !== "") {
            let flag = false;
            this.state.chipsArray.forEach((item) => {
                if (item.label === this.searchText.trim()) {
                    flag = true;
                }
            });
            if (flag === false) {
                this.putTextInChips(this.searchText);
                this.searchText = "";
            }
            document.querySelector(".serachInputBox").value = "";
        }
    };
    putTextInChips = (text) => {
        this.props.setRippleNameStr(this.state.rippleNameStr);
        this.chipsArray = [...this.state.chipsArray];
        if (this.chipsArray.length === null || this.chipsArray.length === 0) {
            this.chipsArray.push({
                key: 0,
                label: text.trim(),
            });
        } else {
            let index = 0;
            index = this.chipsArray.length;
            this.chipsArray.push({
                key: index,
                label: text.trim(),
            });
        }
        this.setState({ chipsArray: this.chipsArray });
        this.props.setChipArray(this.chipsArray);
    };
    getChipsText = (value) => {
        let str = [];
        this.chipsArray = [...value];
        this.chipsArray.forEach((element, index) => {
            if (index <= this.chipsArray.length - 1) {
                str.push(element.label);
            } else {
                str = "";
                str = str + element.label;
            }
        });
        this.props.setValue(str);

        return str;
    };
    getSearchedText = () => {
        let str = [];
        this.chipsArray = [...this.chipsArray];
        this.chipsArray.forEach((element, index) => {
            if (index <= this.chipsArray.length - 1) {
                str.push(element.label);
            } else {
                str = "";
                str = str + element.label;
            }
        });
        return str;
    };
    convertJSDateToExcelDate = (inDate) => {
        const retValue =
            25569 +
            (inDate.getTime() - inDate.getTimezoneOffset() * 60 * 1000) /
                (1000 * 60 * 60 * 24);
        return Number(retValue.toString().split(".")[0]);
    };
    renderViewDataModal = () => {
        return (
            <ModalView
                showHeader="false"
                footer="false"
                show={this.state.showViewModal}
                size="lg"
                onHide={this.closeModal}
                title=""
                onclick={this.closeModal}
                submitButtonText="Close"
            >
                {this.renderViewDocumentModal()}
            </ModalView>
        );
    };
    renderViewDocumentModal = () => {
        return (
            <div className="container">
                <div className="row w-100">
                    <span className="mainTitle textEllipsis mb-3">
                        {this.props.rippleNameStr}
                    </span>
                </div>
                <div className="row w-100">
                    <div className="col-8">
                        <ChipsView items={this.state.chipsArray}></ChipsView>
                    </div>
                    <div
                        className="col-4"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <button
                            className="btn btnLabel doCenter newFontMontserrat"
                            onClick={this.closeModal}
                            title="VIEW IN RIPPLE"
                        >
                            VIEW IN RIPPLE
                        </button>
                    </div>
                </div>
                <RippleLogTableView
                    title={this.title}
                    rows={this.viewModalData}
                    showModal={this.state.showViewModal}
                    columns={this.headCells}
                    showDocModalView={this.showDocModalView}
                    handleClick={(e, index) => {
                        this.handleViewTableRowClick(e, index);
                    }}
                ></RippleLogTableView>
            </div>
        );
    };

    //***********  document Viewer Logic start */

    renderDocumentViewerModal = (event) => {
        return (
            <DocViwer
                docName={this.state.currentShowFileName}
                fileLocation={this.state.currentShowFileLocation}
            ></DocViwer>
        );
    };
    showDocModalView = (event, index, fileName, row) => {
        http.sendRestRequest(
            `document/documentDetail?documentId=${row.documentId}`
        )
            .then((response) => {
                this.setState({
                    showDocModal: true,
                    currentShowFileLocation: response,
                    currentShowFileName: response.documentName,
                });
            })
            .catch((error) => {
                this.showAlertView("", error.error);
                console.log(error);
            });
    };
    closeDocViewer = () => {
        this.setState({
            showDocModal: false,
            // currentShowFileLocation: "",
        });
    };
    renderDocumentViewer = () => {
        return (
            <DocumentViewerModal
                showHeader="false"
                footer="false"
                show={this.state.showDocModal}
                size="lg"
                onHide={this.closeDocViewer}
                title=""
                onclick={() => {}}
                submitButtonText="Close"
                fileSource={this.state.currentShowFileLocation}
            >
                {this.renderDocumentViewerModal()}
            </DocumentViewerModal>
        );
    };
    isWorkspaceAndSpaceSelected = () => {
        if (
            this.props.selectedWorkspaceIndex !== null &&
            this.props.selectedSpaceIndex !== null
        ) {
            return true;
        } else {
            return false;
        }
    };
    setTimelineView = () => {
        this.setState({ timelineExpanded: !this.state.timelineExpanded });
    };
    getFormattedDate = (txDate) => {
        let oldDate = new Date(txDate)
            .toLocaleString("en-US", { timeZone: "UTC" })
            .split(",")[0];
        return oldDate;
    };
    getDocuments = (list, object, workspaceId) => {
        for (const key in object) {
            if (Object.hasOwnProperty.call(object, key)) {
                const element = object[key];
                if (element.documentId) {
                    let obj = {};
                    obj["documentId"] = element.documentId;
                    obj["document"] = element.documentName;
                    obj["createdOn"] = element.createdOn;
                    obj["date"] = this.getFormattedDate(
                        element.createdOn ? element.createdOn : Date.now()
                    );
                    obj["from"] = element.documentName;
                    obj["to"] = element.documentName;
                    obj["spaceId"] = element.spaceId;
                    obj["workspaceId"] = workspaceId;
                    list.push(obj);
                } else {
                    this.getDocuments(list, element.children, workspaceId);
                }
            }
        }
    };
    clearChips = () => {
        this.props.setChipArray([]);
        this.setState({
            chipsArray: [],
        });
    };
    generateTimelineArray = (responseHierarchyInfo) => {
        let list = [];
        const documentList = [];
        Object.keys(responseHierarchyInfo).forEach((id) => {
            let obj = {};
            let documentInfo = responseHierarchyInfo[id];
            obj["documentId"] = documentInfo.documentId;
            obj["document"] = documentInfo.docName;
            obj["createdOn"] = documentInfo.createdOn;
            obj["date"] = this.getFormattedDate(
                documentInfo.createdOn ? documentInfo.createdOn : Date.now()
            );
            obj["from"] = documentInfo.docName;
            obj["to"] = documentInfo.docName;
            list.push(obj);
        });
        responseHierarchyInfo.forEach((element, index) => {
            const spaces = element.spaces;
            for (const space in spaces) {
                if (Object.hasOwnProperty.call(spaces, space)) {
                    const spaceObject = spaces[space];
                    this.getDocuments(
                        documentList,
                        spaceObject.children,
                        element.workspaceId
                    );
                }
            }
        });

        documentList.sort(function (a, b) {
            return new Date(b.createdOn) - new Date(a.createdOn);
        });

        return documentList;
    };
    closeDocumentDisplayModal = () => {
        this.setState({
            showDocumentModal: false,
            // currentShowFileLocation: "",
        });
    };
    getValue = (value) => {
        this.setState({
            selectedIndex: -1,
        });
        this.setState({
            getNode: value,
        });
    };
    getRippleName = (value) => {
        this.setState({
            getNode: "",
        });

        this.setState({
            getRippleNode: value,
        });
    };
    getSelectedIndex = (value) => {
        this.setState({
            selectedIndex: value,
        });
    };

    renderDocumentDisplayModal = (documentId) => {
        this.getDocumentFromServer(documentId);
    };
    getDocumentFromServer = (documentId) => {
        http.sendRestRequest(`document/documentDetail?documentId=${documentId}`)
            .then((response) => {
                this.setState({
                    selectedDocument: response.documentName,
                    showDocumentModal: true,
                    currentShowFileName: response.documentName,
                });
                const fileType = response.documentName.split(".").pop();
                if (fileType === "pdf") {
                    setTimeout(() => {
                        this.setState({
                            currentShowFileLocation: response,
                        });
                    }, 1);
                } else {
                    this.setState({
                        currentShowFileLocation: response,
                    });
                }
            })
            .catch((error) => {
                this.showAlertView("", error.error);
                console.log(error);
            });
    };
    renderViewDocumentModal = () => {
        return (
            <DocViwer
                docName={this.state.selectedDocument}
                fileLocation={this.state.currentShowFileLocation}
            ></DocViwer>
        );
    };

    getFilterDate = () => {
        let dateStr = [];
        if (
            this.props.selectedPeriod !== "" &&
            this.props.selectedPeriod !== "Custom" &&
            Array.isArray(this.props.activeRipple)
        ) {
            dateStr.push(this.props.selectedPeriod);
        } else if (
            this.state.selectedStartDate !== "" &&
            this.state.selectedEndDate !== "" &&
            Array.isArray(this.props.activeRipple)
        ) {
            dateStr.push(this.state.selectedStartDate);
            dateStr.push(this.state.selectedEndDate);
        } else if (
            !Array.isArray(this.props.activeRipple) &&
            this.props.activeRipple.hasOwnProperty("filterCriteriaDates")
        ) {
            const dateRangeString = this.props.activeRipple.filterCriteriaDates;
            if (dateRangeString === "") return [];
            const [startDateString, endDateString] = dateRangeString.split("~");
            const startDate = new Date(startDateString);
            const endDate = new Date(endDateString);
            this.setState({
                selectedStartDate: format(startDate, "yyyy-MM-dd"),
                selectedEndDate: format(endDate, "yyyy-MM-dd"),
            });

            const startUtcDate = new Date(startDate.toUTCString());
            const endUtcDate = new Date(endDate.toUTCString());
            const convertedDateStart = startUtcDate
                .toISOString()
                .slice(0, 19)
                .replace("T", " ");
            const convertedDateEnd = endUtcDate
                .toISOString()
                .slice(0, 19)
                .replace("T", " ");

            this.props.setSelectedPeriod("Custom");
            dateStr.push(convertedDateStart, convertedDateEnd);
        }
        return dateStr;
    };
    validateDateFilters = () => {
        if (
            this.props.selectedPeriod === "" &&
            this.state.selectedStartDate !== "" &&
            this.state.selectedEndDate === ""
        ) {
            this.showAlertView("", "Select start date and end date");
            return false;
        } else if (
            this.props.selectedPeriod === "" &&
            this.state.selectedEndDate !== "" &&
            this.state.selectedStartDate === ""
        ) {
            this.showAlertView("", "Select start date and end date");
            return false;
        }
        if (
            (this.props.selectedPeriod === "" &&
                this.state.selectedStartDate !== "") ||
            this.state.selectedEndDate !== ""
        ) {
            if (this.state.selectedStartDate > this.state.selectedEndDate) {
                this.showAlertView(
                    "",
                    "Start date should be lesser than end date"
                );
                this.props.setRippleResult(null);
                this.props.setRippleKeywordSearched(false);
                this.props.setRippleDocumentResponse([]);
                this.props.setTimelineDocumentList([]);
                this.setState({
                    showFilterIcon: false,

                    showLoder: false,
                });
                return false;
            }
        }
        return true;
    };
    convertIntergerToString = (intArray) => {
        let stringArray = [];
        Object.keys(intArray).forEach((item, index) => {
            stringArray.push(item.toString());
        });
        return stringArray;
    };
    searchRippleByKeywords = (data) => {
        let promise = new Promise((resolve, reject) => {
            let url = "search/searchText";
            http.sendRestRequest(url, "POST", {
                ...data,
                rippleRegistryId: this.rippleId,
                rippleName: this.props.rippleNameStr.trim(),
            })
                .then((documentList) => {
                    this.setState({ tabKey: "Timeline" });
                    resolve(documentList);
                    this.props.setRippleId(documentList);
                })
                .catch((errResp) => {
                    console.log(errResp);
                    reject(errResp);
                    this.setState({ showLoder: false });
                });
        });
        return promise;
    };
    searchRipple = (filterObject) => {
        this.props.setSelectedDateArray(this.state.selectedTimeFilterLocal);
        let dateFilter = [];
        let folderFilter = [];
        let spaceFilter = [];
        let workspaceFilter = [];
        if (filterObject && Object.keys(filterObject).length) {
            dateFilter = filterObject?.timeFilter || [];
            folderFilter = filterObject?.folders || [];
            spaceFilter = filterObject?.spaces || [];
            workspaceFilter = filterObject?.workspaces || [];
        }
        let keyArray;
        if (this.chipsArray.length > 0)
            this.props.setChipArray(this.chipsArray);
        if (
            this.props.chipsArray.length > 0 &&
            this.chipsArray.length < this.props.chipsArray.length
        )
            keyArray = this.getChipsText(this.props.chipsArray);
        else keyArray = this.getChipsText(this.chipsArray);
        let keyString = keyArray.join("&");

        this.folderNames = [];
        this.state.selectedFoldersArray.forEach((folder, index) => {
            this.folderNames.push(folder.id);
        });
        let isDateValid = this.validateDateFilters();
        if (isDateValid) {
            let data = {
                rippleSearchStrings: keyString,
                spaceFilter: spaceFilter,
                workspaceFilter: workspaceFilter,
                folderFilter: folderFilter,
                dateFilter: dateFilter,
            };
            this.setState({ showLoder: true });
            this.searchRippleByKeywords(data)
                .then((resp) => {
                    this.setState({
                        showDrawer: true,
                    });
                    let rippleHierarchy = resp.rippleHierarchy;
                    let documentHierachies = [];
                    for (const key in rippleHierarchy) {
                        if (Object.hasOwnProperty.call(rippleHierarchy, key)) {
                            const element = rippleHierarchy[key];
                            documentHierachies.push(element);
                        }
                    }
                    let rippleChatMessages = resp.rippleChatMessages;
                    let result = [...documentHierachies, ...rippleChatMessages];

                    let docArray = this.convertIntergerToString(result);
                    let timelineArray =
                        this.generateTimelineArray(documentHierachies);

                    this.documentArray = docArray;
                    this.props.setRippleResult(result);
                    if (filterObject)
                        this.props.setRippleFilterAction(filterObject);
                    this.showDrawer();
                    this.props.setRippleKeywordSearched(true);
                    this.props.setRippleDocumentResponse(docArray);
                    this.props.setTimelineDocumentList(timelineArray);
                    this.setState({
                        isDesableNameBtn: true,
                        showFilterIcon: true,
                        showLoder: false,
                    });
                })
                .catch((err) => {
                    this.showAlertView("", err.error);
                    this.setState({ showLoder: false });
                });
        }
    };
    onClickRipple = (e, filterObject) => {
        if (e && e.target.innerHTML) {
            this.props.setChatRippleMsg("");
            this.setState({ errRipple: "" });
        }

        if (this.searchText.trim() !== "") {
            this.createChip(this.searchText);
            this.searchRipple(filterObject);
        } else if (
            this.searchText.trim() == "" &&
            this.props.chipsArray.length > 0
        ) {
            this.searchRipple(filterObject);
        } else {
            this.setState({
                emailError: "Please add at least one keyword to search.",
            });
        }
    };

    saveRipple = () => {
        let promise = new Promise((resolve, reject) => {
            this.props.setRippleNameStr(this.props.rippleNameStr.trim());
            let temp = this.rippleManager.checkExistingRippleName(
                this.props.rippleNameStr
            );
            if (temp === false) {
                let rippleData = [this.props.rippleNameStr, "0"];
                this.rippleManager
                    .insertRippleRow(rippleData)
                    .then((rippleResponse) => {
                        this.rippleManager.createDataStructreForRipple();
                        this.rippleId = this.rippleManager.getRippleIdByName(
                            this.props.rippleNameStr
                        );
                        resolve(this.rippleId);
                    });
            }
        });
        return promise;
    };

    renderTitle = () => {
        return (
            <TitleBar
                username={this.props.username}
                title={"Ripple"}
                titleImage={"./images/Ripple.svg"}
            ></TitleBar>
        );
    };
    handleDeleteChipItem = (chipToDelete) => {
        let items = this.chipsArray;
        let indexToRemove = items.indexOf(chipToDelete);
        items.splice(indexToRemove, 1);
        this.setState({ chipsArray: items });
        this.props.setChipArray(items);
    };
    renderClassForChips = () => {
        this.chipsArray = [...this.state.chipsArray];
        if (this.chipsArray.length <= 3) {
            return "col-" + this.chipsArray.length * 3;
        } else {
            return "col-9";
        }
    };
    renderSearchButton = () => {
        return (
            <div
                className={"doCenter p-0 pr-2 m-0 w-100 p-0 "}
                style={{
                    flexDirection: this.state.emailError !== "" ? "column" : "",
                }}
            >
                <div className="row m-0 w-100">
                    <div className="col p-0">
                        {this.props.showDrawer === false &&
                        this.state.showFilterIcon === true ? (
                            <div
                                className="row parentSearchDiv w-100 doCenter"
                                style={{
                                    justifyContent: "space-between",
                                    marginLeft: "2.5rem",
                                    marginRight: "-2.5rem",
                                    border: "none",
                                    boxShadow: "none",
                                }}
                            >
                                <div className="pl-0  pr-0 search_text_ripple">
                                    <div
                                        className="row w-100 m-0"
                                        id="serachBarDiv"
                                    >
                                        <TextBox
                                            divClass={"rippleSerachDiv"}
                                            className="getAddress form-control serachInputBox"
                                            id="rippleSearchTextBox"
                                            clearAfterChange={true}
                                            type="text"
                                            label="Search your information universe"
                                            placeholder={true}
                                            onkeydown={this.createChip}
                                            handleRippleKeys={
                                                this.handleKeyDown
                                            }
                                            onchange={this.setFromText}
                                        ></TextBox>
                                    </div>
                                </div>
                                <div className="pl-0 pr-0 search-btn">
                                    <div
                                        id="RippleBtn"
                                        style={{ padding: "14px 20px" }}
                                        onClick={this.onClickRipple}
                                    >
                                        <img
                                            src="./images/ws.png"
                                            alt="search"
                                        />
                                    </div>
                                </div>
                                {this.props.chipsArray.length ? (
                                    <div
                                        className="pl-0 pr-0 "
                                        style={
                                            this.state.showDrawer === true
                                                ? {
                                                      position: "absolute",
                                                      marginLeft: "70%",
                                                      marginTop: "1%",
                                                  }
                                                : {
                                                      position: "absolute",
                                                      marginLeft: "440px",
                                                      marginTop: "1%",
                                                  }
                                        }
                                    >
                                        <div
                                            id="RippleBtn"
                                            style={{ padding: "14px 20px" }}
                                            onClick={this.clearChips}
                                        >
                                            <img
                                                src="./images/ripple_cancel.png"
                                                alt="cancel"
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : (
                            <>
                                <div
                                    className="row parentSearchDiv w-100 doCenter"
                                    style={{
                                        justifyContent: "space-between",
                                        marginLeft: "2.5rem",
                                        marginRight: "-2.5rem",
                                        border: "none",
                                        boxShadow: "none",
                                    }}
                                    id="main_search"
                                >
                                    <div className="pl-0 doCenter pr-0 search_text_ripple">
                                        <div
                                            className="row w-100 m-0"
                                            id="serachBarDiv"
                                        >
                                            <TextBox
                                                divClass={"rippleSerachDiv"}
                                                className="getAddress form-control serachInputBox"
                                                id="rippleSearchTextBox"
                                                clearAfterChange={true}
                                                type="text"
                                                label={
                                                    this.props.chipsArray.length
                                                        ? ""
                                                        : "Search your information universe"
                                                }
                                                placeholder={true}
                                                onkeydown={this.createChip}
                                                handleRippleKeys={
                                                    this.handleKeyDown
                                                }
                                                onchange={this.setFromText}
                                            ></TextBox>
                                        </div>
                                    </div>
                                    <div
                                        className="pl-0 pr-0 search-btn"
                                        style={
                                            !this.props.rippleKeywordSearched &&
                                            this.props
                                                .rippleDocumentResponse ===
                                                null &&
                                            this.state.showDrawer === true
                                                ? { left: "553px" }
                                                : {}
                                        }
                                    >
                                        <div
                                            style={{
                                                cursor: "pointer",
                                                padding: "14px 20px",
                                            }}
                                            id="RippleBtn"
                                            onClick={this.onClickRipple}
                                        >
                                            <img
                                                src="./images/ws.png"
                                                alt="search"
                                            />
                                        </div>
                                        {this.props.chipsArray.length ? (
                                            <div className="pl-0 pr-0 search-cancel">
                                                <div
                                                    id="RippleBtn"
                                                    style={{
                                                        padding: "14px 20px",
                                                    }}
                                                    onClick={this.clearChips}
                                                >
                                                    <img
                                                        src="./images/ripple_cancel.png"
                                                        alt="cancel"
                                                    />
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>

                                {this.state.errRipple && (
                                    <>
                                        <small
                                            style={{
                                                color: "red",
                                                paddingLeft: "60px",
                                                width: "90%",
                                            }}
                                        >
                                            {this.state.errRipple}
                                        </small>
                                        <br />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {this.state.emailError && (
                    <div className="w-100 m-0">
                        <small
                            style={{
                                color: "red",
                                marginTop: "10px",
                                marginLeft: "50px",
                                display: "inline-block",
                            }}
                        >
                            {this.state.emailError}
                        </small>
                    </div>
                )}
            </div>
        );
    };
    clearRipple = () => {
        this.resetAll();

        this.props.setActiveRipple([]);
        this.setState({ selectedStartDate: "" });
        this.setState({ selectedEndDate: "" });
        this.props.setShowDrawer(false);
        this.props.setChatRippleMsg("");
        this.props.setRippleDocumentResponse(null);
        this.props.setRippleKeywordSearched(false);
        this.props.setTimelineDocumentList([]);
        this.props.setRippleResult(null);
        this.props.setChipArray([]);
        this.props.setSelectedWorkspaceIndex(null);
        this.props.setSelectedFolderIndex(null);
        this.props.setSelectedSpaceIndex(null);
        this.props.setSelectedPeriod("");
        this.props.setSpaceArray([]);
        this.props.setRippleNameStr("");
        this.props.setRippleId("");
        this.props.setValue("");
        this.selectedWorkspaceIndex = [];
        this.selectedWorkspaceIDS = [];
        this.setState({
            chipsArray: [],
            rippleNameStr: "Name Ripple",
            errRipple: "",
        });
        this.rippleId = -1;
        this.setState({
            selectedWorkspacesArray: [],
            selectedSpacesArray: [],
            selectedFoldersArray: [],
            showDrawer: false,
        });
        this.setState({
            selectedTimeFilterLocal: {
                startDate: "",
                endDate: "",
                selectedTimeType: "",
            },
            selectedTimeFilter: [],
        });
        this.props.setSelectedDateArray([]);
        this.props.setRippleFilterAction(null);
    };
    resetAll = () => {
        this.spaceFolderList = [];

        this.setState({
            dateErr: false,
            selectedWorkspacesArray: [],
            selectedSpacesArray: [],
            selectedFoldersArray: [],
            selectedTimeFilterLocal: {
                startDate: "",
                endDate: "",
                selectedTimeType: "",
            },
            selectedTimeFilter: [],
        });
        this.selectedSpaceIDS = [];
        this.props.setSelectedWorkspaceArray([]);
        this.props.setSelectedSpaceArray([]);
        this.props.setSelectedDateArray([]);
        this.props.setSelectedFolderArray([]);
        this.props.setSpaceArray([]);
        this.props.setSelectedPeriod("");
        this.setDateErr(true);
    };

    setDateErr = (err) => {
        this.setState({ dateErr: err });
    };
    test = (e) => {
        if (e.key === "Enter" && this.props.rippleNameStr.length === 0) {
            e.preventDefault();
        } else {
            this.updateRippleName();
        }
    };
    handleDown = (e) => {
        if (
            (e.key === " " && this.props.rippleNameStr.length === 0) ||
            (e.key === "Enter" && this.props.rippleNameStr.length === 0)
        ) {
            e.preventDefault();
        }
    };
    setFromText = (value, id, event) => {
        switch (id) {
            case "rippleName":
                {
                    if (/[^a-zA-Z0-9\s]+/.test(value)) {
                        this.setState({
                            error: "Special characters are not allowed in the name.",
                        });
                    } else {
                        this.props.setRippleNameStr(value);
                        this.setState({
                            rippleNameStr: value,
                            error: "",
                        });
                    }
                }
                break;
            case "rippleSearchTextBox":
                {
                    this.searchText = value;
                    this.setState({
                        emailError: "",
                    });
                }
                break;
            case "rippleSearchTextBox1":
                {
                    this.searchText = value;
                    this.setState({
                        emailError: "",
                    });
                }
                break;
            default:
                break;
        }
    };

    closeDoneModal = () => {
        this.setState({
            showRippleNameModal: false,
            showViewModal: false,
            error: "",
        });
    };
    closeModal = () => {
        this.props.setRippleNameStr("");
        this.props.setChatRippleMsg("");
        this.setState({
            showRippleNameModal: false,
            showViewModal: false,
            error: "",
            rippleNameStr: "",
        });
    };
    showRippleNameModal = () => {
        if (
            this.props.rippleNameStr === "" ||
            (this.state.rippleNameStr === "Name Ripple" &&
                this.props.rippleNameStr !== "")
        )
            this.setState({ showRippleNameModal: true, rippleNameStr: "" });
    };
    updateRippleName = () => {
        let data = {
            rippleRegistryId: this.props.rippleId.rippleRegistryId,
            rippleName: this.props.rippleNameStr,
        };
        if (this.isValidRippleName()) {
            this.closeDoneModal();
        }
        if (this.props.rippleNameStr === "" && this.props.chipsArray.length) {
            this.searchRipple();
        }
        if (this.props.showDrawer === true && this.isValidRippleName()) {
            this.rippleManager.updateRippleName(data);
        }
    };
    isValidRippleName = () => {
        let isValidName = this.props.chipsArray.length
            ? validateName(this.props.rippleNameStr)
            : validateName(this.state.rippleNameStr);
        if (isValidName === true) {
            if (this.props.chipsArray.length) {
                if (
                    this.state.rippleLogData.includes(
                        this.props.rippleNameStr.trim().toLowerCase()
                    )
                ) {
                    this.setState({ error: "Ripple name already exist" });
                    this.props.setRippleNameStr("");
                    return false;
                } else {
                    this.setState({ error: "" });
                    return true;
                }
            } else {
                if (
                    this.state.rippleLogData.includes(
                        this.state.rippleNameStr.trim().toLowerCase()
                    )
                ) {
                    this.setState({
                        error: "Ripple name already exist",
                        rippleNameStr: "",
                    });

                    return false;
                } else {
                    this.setState({ error: "" });
                    return true;
                }
            }
        } else {
            this.setState({ error: isValidName, rippleNameStr: "" });
            this.props.setRippleNameStr("");
            return false;
        }
    };
    renderRippleNameModalData = () => {
        return (
            <div className="row doCenter" style={{ flexDirection: "column" }}>
                <img
                    className="addWorkspaceModalImg"
                    src="./images/Ripple.svg"
                ></img>
                <TextBox
                    id="rippleName"
                    className="updateSpaceName loginPageTextBox loginPageBorder paddingLeft"
                    type="text"
                    onKeyDown={this.handleDown}
                    onchange={this.setFromText}
                    label={"Name your Ripple..."}
                    value={
                        this.props.rippleNameStr === "Name Ripple"
                            ? ""
                            : this.props.rippleNameStr
                    }
                    formControl="Off"
                    doCenter={true}
                    onFocus={(e) => {
                        this.setState({ error: "" });
                    }}
                ></TextBox>
                {this.state.error && (
                    <>
                        <small
                            style={{
                                color: "red",
                                paddingLeft: "18px",
                                width: "90%",
                            }}
                        >
                            {this.state.error}
                        </small>
                        <br />
                    </>
                )}
                <input
                    autocomplete="off"
                    type="button"
                    id="doneRippleNameBtn"
                    className="btn btn-light viewFSbtn newFontMontserrat loginPageBorder loginPageButton"
                    style={{ height: "40px", marginTop: "45px" }}
                    value="DONE"
                    onClick={this.test}
                    disabled={
                        this.props.chipsArray.length
                            ? this.props.rippleNameStr.trim() === "" ||
                              this.props.rippleNameStr === undefined
                                ? true
                                : false
                            : this.state.rippleNameStr.trim() === "" ||
                              this.state.rippleNameStr === undefined
                            ? true
                            : false
                    }
                ></input>
            </div>
        );
    };
    renderRippleNameModal = () => {
        return (
            <ModalView
                showHeader="false"
                footer="false"
                show={this.state.showRippleNameModal}
                size="md"
                onHide={this.closeModal}
                title=""
                onclick={this.closeModal}
                submitButtonText="Close"
            >
                {this.renderRippleNameModalData()}
            </ModalView>
        );
    };
    renderTopBar = () => {
        return (
            <InlineRow className="rippleTopbar mt-2 m-0 sticky" noRow={true}>
                <div className="renderTop">
                    <div
                        className="ripple-brand"
                        style={{ marginRight: "0%" }}
                        onClick={this.clearRipple}
                    >
                        <div>
                            <img
                                src="./images/ri1.svg"
                                alt="Ripple"
                                style={{ width: "80px" }}
                            />
                        </div>
                    </div>

                    <div
                        style={{
                            position: "relative",
                            padding: "12px 0px",
                            marginRight: "43%",
                            marginBottom: "2%",
                        }}
                    >
                        <div
                            className={"doCenter p-0 pr-2 m-0 w-100 p-0 "}
                            style={{
                                flexDirection:
                                    this.state.emailError !== ""
                                        ? "column"
                                        : "",
                            }}
                        >
                            <div className="row m-0 w-100">
                                <div className="col p-0">
                                    {this.props.showDrawer === false &&
                                    this.state.showFilterIcon === true ? (
                                        <div
                                            className="row parentSearchDiv w-100 doCenter"
                                            style={{
                                                justifyContent: "space-between",
                                                marginLeft: "2.5rem",
                                                marginRight: "-2.5rem",
                                                border: "none",
                                                boxShadow: "none",
                                            }}
                                        >
                                            <div className="pl-0 doCenter pr-0 search_text_ripple">
                                                <div
                                                    className="row w-100 m-0"
                                                    id="serachBarDiv"
                                                >
                                                    <TextBox
                                                        divClass={
                                                            "rippleSerachDiv"
                                                        }
                                                        className="getAddress form-control serachInputBox"
                                                        id="rippleSearchTextBox1"
                                                        clearAfterChange={true}
                                                        type="text"
                                                        label="Search your information universe"
                                                        placeholder={true}
                                                        onkeydown={
                                                            this.createChip
                                                        }
                                                        handleRippleKeys={
                                                            this.handleKeyDown
                                                        }
                                                        onchange={
                                                            this.setFromText
                                                        }
                                                    ></TextBox>
                                                </div>
                                            </div>
                                            <div className="pl-0 pr-0 search-btn1">
                                                <div
                                                    id="RippleBtn"
                                                    style={{
                                                        padding: "14px 20px",
                                                    }}
                                                    onClick={this.onClickRipple}
                                                >
                                                    <img
                                                        src="./images/ws.png"
                                                        alt="search"
                                                    />
                                                </div>
                                            </div>
                                            {this.props.chipsArray.length ? (
                                                <div
                                                    className="pl-0 pr-0 "
                                                    style={
                                                        this.state
                                                            .showDrawer === true
                                                            ? {
                                                                  position:
                                                                      "absolute",
                                                                  marginLeft:
                                                                      "70%",
                                                                  marginTop:
                                                                      "1%",
                                                              }
                                                            : {
                                                                  position:
                                                                      "absolute",
                                                                  marginLeft:
                                                                      "440px",
                                                                  marginTop:
                                                                      "1%",
                                                              }
                                                    }
                                                >
                                                    <div
                                                        id="RippleBtn"
                                                        style={{
                                                            padding:
                                                                "14px 20px",
                                                        }}
                                                        onClick={
                                                            this.clearChips
                                                        }
                                                    >
                                                        <img
                                                            src="./images/ripple_cancel.png"
                                                            alt="cancel"
                                                        />
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : (
                                        <>
                                            <div
                                                className="row parentSearchDiv w-100 doCenter"
                                                style={{
                                                    justifyContent:
                                                        "space-between",
                                                    marginLeft: "2.5rem",
                                                    marginRight: "-2.5rem",
                                                    border: "none",
                                                    boxShadow: "none",
                                                }}
                                            >
                                                <div className="pl-0 doCenter pr-0 search_text_ripple">
                                                    <div
                                                        className="row w-100 m-0"
                                                        id="serachBarDiv"
                                                    >
                                                        <TextBox
                                                            divClass={
                                                                "rippleSerachDiv"
                                                            }
                                                            className="getAddress form-control serachInputBox"
                                                            id="rippleSearchTextBox1"
                                                            clearAfterChange={
                                                                true
                                                            }
                                                            type="text"
                                                            label={
                                                                this.props
                                                                    .chipsArray
                                                                    .length
                                                                    ? ""
                                                                    : "Search your information universe"
                                                            }
                                                            placeholder={true}
                                                            onkeydown={
                                                                this.createChip
                                                            }
                                                            handleRippleKeys={
                                                                this
                                                                    .handleKeyDown
                                                            }
                                                            onchange={
                                                                this.setFromText
                                                            }
                                                        ></TextBox>
                                                    </div>
                                                </div>
                                                <div
                                                    className="pl-0 pr-0 search-btn1"
                                                    style={
                                                        !this.props
                                                            .rippleKeywordSearched &&
                                                        this.props
                                                            .rippleDocumentResponse ===
                                                            null &&
                                                        this.state
                                                            .showDrawer === true
                                                            ? { left: "553px" }
                                                            : {}
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            cursor: "pointer",
                                                            padding:
                                                                "14px 20px",
                                                        }}
                                                        id="RippleBtn"
                                                        onClick={
                                                            this.onClickRipple
                                                        }
                                                    >
                                                        <img
                                                            src="./images/ws.png"
                                                            alt="search"
                                                        />
                                                    </div>
                                                    {this.props.chipsArray
                                                        .length ? (
                                                        <div className="pl-0 pr-0 search-cancel">
                                                            <div
                                                                id="RippleBtn"
                                                                style={{
                                                                    padding:
                                                                        "14px 20px",
                                                                }}
                                                                onClick={
                                                                    this
                                                                        .clearChips
                                                                }
                                                            >
                                                                <img
                                                                    src="./images/ripple_cancel.png"
                                                                    alt="cancel"
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            {this.state.errRipple && (
                                                <>
                                                    <small
                                                        style={{
                                                            color: "red",
                                                            paddingLeft: "60px",
                                                            width: "90%",
                                                        }}
                                                    >
                                                        {this.state.errRipple}
                                                    </small>
                                                    <br />
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            {this.state.emailError && (
                                <div className="w-100 m-0">
                                    <small
                                        style={{
                                            color: "red",
                                            marginTop: "10px",
                                            marginLeft: "50px",
                                            display: "inline-block",
                                        }}
                                    >
                                        {this.state.emailError}
                                    </small>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </InlineRow>
        );
    };

    handleDrawerClose = () => {
        this.props.setShowDrawer(false);
        this.setState({ showFilterIcon: true });
    };
    showDrawer = () => {
        this.props.setShowDrawer(true);
        this.setState({ showFilterIcon: false });
    };
    renderFilterTab = () => {
        return (
            <RippleFilters
                applyFilters={this.onClickRipple}
                filterObject={null}
            />
        );
    };
    renderTimelineTab = () => {
        return (
            <VerticalTimelineView
                setSelectedIndex={this.getSelectedIndex}
                selectedIndex={this.state.selectedIndex}
                getRippleValue={this.getRippleName}
                selectedNode={this.state.getNode}
                documentList={this.props.timelineDocumentList}
                onNodeClick={this.renderDocumentDisplayModal}
                timelineExpanded={this.state.timelineExpanded}
                marginMultiplier={
                    this.props.timelineDocumentList.length > 10
                        ? this.props.timelineDocumentList.length % 10
                        : 10
                }
                setTimelineView={this.setTimelineView}
                rippleTimeline={true}
                isDrawerOpen={this.props.showDrawer}
            ></VerticalTimelineView>
        );
    };
    renderRightPanel = () => {
        return (
            <div
                className="p-0 rightPanelDrawer invisibleScroller"
                style={
                    this.props.rippleKeywordSearched &&
                    this.props.rippleDocumentResponse
                        ? { marginTop: "85px", zIndex: "99", flex: "1" }
                        : null
                }
            >
                <DrawerView
                    show={this.props.showDrawer || this.state.showDrawer}
                    title="Filter"
                    hideDrawer={this.handleDrawerClose}
                >
                    <Tabs
                        activeKey={this.state.tabKey}
                        id="rippleTimelineTabs"
                        className="m-0 "
                        onSelect={(k) => {
                            this.setState({ tabKey: k });
                        }}
                    >
                        {this.props.rippleKeywordSearched &&
                        this.props.rippleDocumentResponse ? (
                            <Tab eventKey="Timeline" title="Timeline">
                                {this.renderTimelineTab()}
                            </Tab>
                        ) : null}
                        <Tab eventKey="Filter" title="Filter">
                            {this.renderFilterTab()}
                        </Tab>
                    </Tabs>
                </DrawerView>
            </div>
        );
    };

    hideAlertView = () => {
        this.setState({ showAlertModal: false });
    };
    showAlertView = (title, body) => {
        this.errMsgTitle = title;
        this.errMsgBody = body;
        this.setState({ showAlertModal: true });
    };
    renderAlertModal = () => {
        return (
            <AlertDialog
                open={this.state.showAlertModal}
                setOpen={this.hideAlertView}
                title={this.errMsgTitle}
                message={this.errMsgBody}
            ></AlertDialog>
        );
    };

    getData = () => {
        return (
            <>
                {this.renderTopBar()}
                <div
                    style={{
                        marginBottom: "12px",
                        width: "90%", // Adjust the width as needed
                        marginLeft: "5%", // Adjust the margin as needed
                    }}
                >
                    <ChipsView
                        items={this.state.chipsArray}
                        ondelete={this.handleDeleteChipItem}
                    ></ChipsView>
                </div>
                <div className="row m-0" style={{ height: "80vh" }}>
                    <RippleRenderer
                        rippleSelectedNode={this.state.getRippleNode}
                        getNode={this.getValue}
                        onNodeClick={this.renderDocumentDisplayModal}
                        rippleResult={this.props.rippleResult}
                        isDrawerOpen={this.props.showDrawer}
                        documentArray={this.props.rippleDocumentResponse}
                        rippleKeywordSearched={this.props.rippleKeywordSearched}
                        searchedText={this.getSearchedText().join("&")}
                    ></RippleRenderer>
                </div>
            </>
        );
    };
    render() {
        this.rippleDataObject = this.props.activeRipple;
        return (
            <>
                <div
                    className="row m-0 w-100 pl-0 pr-0 contentPaneBottom"
                    style={{
                        display: "flex",
                        padding: "20px",
                        height: "89vh !important",
                        backgroundColor: "#fff !important",
                        position: "relative",
                    }}
                >
                    <div
                        className="col p-0"
                        style={{
                            height: "calc(100vh - 40px)",
                            padding: "20px",
                            flex: "2",
                        }}
                    >
                        {!this.props.rippleKeywordSearched &&
                        this.props.rippleDocumentResponse === null ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "58vh",
                                }}
                            >
                                <div
                                    style={{
                                        width: "100%", // Adjust the width as needed
                                        display: "flex",
                                        margin: "auto 25%",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <div className="ripple-brand">
                                        <div>
                                            <img
                                                src="./images/r2.svg"
                                                alt="Ripple"
                                                style={{ width: "80px" }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            width: "100%",
                                            position: "relative",
                                            padding: "12px 0px",
                                            display: "flex",
                                        }}
                                    >
                                        {this.renderSearchButton()}
                                    </div>
                                    <div
                                        style={{
                                            marginBottom: "12px",
                                            width: "100%",
                                            marginRight: "5%",
                                        }}
                                    >
                                        <ChipsView
                                            items={this.state.chipsArray}
                                            ondelete={this.handleDeleteChipItem}
                                        ></ChipsView>
                                    </div>
                                    <div style={{ marginRight: "10%" }}>
                                        <button
                                            title={
                                                this.state.showDrawer
                                                    ? "Hide Filters"
                                                    : "View Filters"
                                            }
                                            id="ripple-view-filters"
                                            className="cursorPointer"
                                            style={{
                                                background: "#fff",
                                                marginLeft: "20px",
                                                borderRadius: "10px",
                                                color: "#000",
                                                border: "1px solid #000",
                                                padding: "6px 21px",
                                            }}
                                            onClick={() => {
                                                this.setState({
                                                    showDrawer:
                                                        !this.state.showDrawer,
                                                });
                                            }}
                                        >
                                            {this.state.showDrawer
                                                ? "Hide Filters"
                                                : "View Filters"}
                                        </button>
                                        <button
                                            title="Ripple Log"
                                            id="ripple-ripple-log"
                                            className={
                                                this.isReadOnlyUser
                                                    ? "readOnly"
                                                    : "cursorPointer"
                                            }
                                            style={{
                                                background: "#fff",
                                                marginLeft: "20px",
                                                borderRadius: "10px",
                                                color: "#000",
                                                border: "1px solid #000",
                                                padding: "6px 21px",
                                            }}
                                            onClick={
                                                this.isReadOnlyUser
                                                    ? null
                                                    : () => {
                                                          this.setState({
                                                              redirectToRipplelog: true,
                                                          });
                                                      }
                                            }
                                        >
                                            Ripple Log
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            this.getData()
                        )}
                    </div>
                    {this.state.showDrawer === true
                        ? this.renderRightPanel()
                        : null}
                    {this.renderAlertModal()}
                    {this.renderRippleNameModal()}
                    {this.props.rippleKeywordSearched &&
                    this.props.rippleDocumentResponse ? (
                        <div
                            style={{
                                position: "absolute",
                                top: "45px",
                                right: "20px",
                                display: "flex",
                                flexDirection: "row", // Adjust for small screens
                                alignItems: "flex-end", // Adjust for small screens
                            }}
                        >
                            <Button
                                label={
                                    this.props.rippleNameStr === "" ||
                                    this.props.rippleNameStr === undefined
                                        ? "Name Ripple"
                                        : this.props.rippleNameStr.trim()
                                }
                                title={
                                    this.props.rippleNameStr === "" ||
                                    this.props.rippleNameStr === undefined
                                        ? "Name the ripple(search)"
                                        : this.props.rippleNameStr
                                }
                                style={{
                                    background: "#fff",
                                    marginTop: "10px", // Adjust for small screens
                                    borderRadius: "10px",
                                    color: "#000",
                                    border: "1px solid #000",
                                    padding: "4px",
                                    width: "8rem", // Adjust for small screens
                                }}
                                onclick={(e) =>
                                    this.isReadOnlyUser
                                        ? null
                                        : this.showRippleNameModal()
                                }
                                className={
                                    this.isReadOnlyUser
                                        ? "rippleBtnFont displayBlock textEllipsis readOnly"
                                        : "rippleBtnFont displayBlock textEllipsis"
                                }
                            ></Button>
                            <Button
                                showDrawer={this.state.showDrawer}
                                label={
                                    this.state.showDrawer
                                        ? "Hide Filters"
                                        : "Show Filters"
                                }
                                title={
                                    this.state.showDrawer
                                        ? "Hide Filters"
                                        : "Show Filters"
                                }
                                style={{
                                    background: "#fff",
                                    marginTop: "10px", // Adjust for small screens
                                    borderRadius: "10px",
                                    color: "#000",
                                    border: "1px solid #000",
                                    padding: "4px",
                                    width: "8rem", // Adjust for small screens
                                }}
                                onClick={() => {
                                    this.setState({
                                        showDrawer: !this.state.showDrawer,
                                    });
                                }}
                                className={
                                    "rippleBtnFont displayBlock textEllipsis"
                                }
                            ></Button>
                        </div>
                    ) : null}
                </div>
                <Loader
                    show={this.state.showLoder || this.state.showWSHLoader}
                />
                {this.state.showDocumentModal ? (
                    <DocumentViewerModal
                        showHeader={true}
                        footer="false"
                        documentId={
                            this.state.currentShowFileLocation.documentId
                        }
                        show={this.state.showDocumentModal}
                        size="lg"
                        onHide={this.closeDocumentDisplayModal}
                        onclick={() => {}}
                        isChecked={true}
                        submitButtonText="Close"
                        headerTitle={this.state.currentShowFileName}
                        fileSource={this.state.currentShowFileLocation}
                    >
                        {this.renderViewDocumentModal()}
                    </DocumentViewerModal>
                ) : null}
                {this.state.redirectToRipplelog ? (
                    <Navigate
                        to={`${
                            this.basePathName ? "/" + this.basePathName : ""
                        }/ripplelog`}
                        replace={true}
                    />
                ) : null}
            </>
        );
    }
}

const mapDispatchToProps = () => {
    return {
        setActiveRipple,
        fetchHierarchyResponse,
        setRippleResult,
        resetHierarchy,
        setShowDrawer,
        setChipArray,
        setChatRippleMsg,
        setSpaceArray,
        setRippleDocumentResponse,
        setRippleKeywordSearched,
        setTimelineDocumentList,
        setSelectedFolderIndex,
        setSelectedSpaceIndex,
        setSelectedWorkspaceIndex,
        setSelectedPeriod,
        setRippleNameStr,
        setRippleId,
        setDeleteRippleId,
        setValue,
        setActivePage,
        setSelectedWorkspaceArray,
        setSelectedSpaceArray,
        setSelectedDateArray,
        setSelectedFolderArray,
        setRippleFilterAction,
    };
};

const mapStateToProps = (state) => {
    return {
        collabInfo: state.collabInfo,
        activeRipple: state.activeRipple,
        showDrawer: state.showDrawer,
        errRipple: state.errRipple,
        chipsArray: state.chipsArray,
        showValue: state.showValue,
        selectedPeriod: state.selectedPeriod,
        rippleNameStr: state.rippleNameStr,
        rippleId: state.rippleId,
        rippleDeleteId: state.rippleDeleteId,
        spaceArray: state.spaceArray,
        rippleDocumentResponse: state.rippleDocumentResponse,
        rippleKeywordSearched: state.rippleKeywordSearched,
        selectedFolderIndex: state.selectedFolderIndex,
        selectedWorkspaceIndex: state.selectedWorkspaceIndex,
        selectedSpaceIndex: state.selectedSpaceIndex,
        selectedWorkspaceArray: state.selectedWorkspaceArray,
        selectedSpaceArray: state.selectedSpaceArray,
        selectedFolderArray: state.selectedFolderArray,
        timelineDocumentList: state.timelineDocumentList,
        documentHierarchyInfo: state.documentHierarchyInfo,
        spaceManager: state.spaceManager,
        workspaceManager: state.workspaceManager,
        cuboidInfoByName: state.cuboidInfoByName,
        cuboidInfoById: state.cuboidInfoById,
        hierarchyResponse: state.hierarchyResponse,
        workspaceNameToId: state.workspaceNameToId,
        spaceNameToId: state.spaceNameToId,
        selectedDateArray: state.selectedDateArray,
        workspaceList: state.workspaceList,
        rippleFilterData: state.rippleFilterData,
    };
};
export default connect(mapStateToProps, mapDispatchToProps())(Ripple);
