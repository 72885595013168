import React, { useEffect, useState } from "react";
import { BrowserRouter, NavLink, useLocation } from "react-router-dom";
import Snackbar from "../../../stylized/CustomSnackbar/CustomSnackbar";
import Loader from "../../../stylized/Loader";
import WorkspaceList from "./WorkspaceList";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchModulesAction,
    fetchWorkspaceListAction,
    setActivePageAction,
} from "../../../../services/services/app";
import "./style.css";
import UserSection from "./user-section";
const alertNotifyStyle = {
    position: "absolute",
    maxWidth: "40px",
    backgroundColor: "#FF0000",
    color: "white",
    height: "16px",
    padding: "1px 4px",
    borderRadius: "100px",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "16px",
    letterSpacing: "0.04px",
    textAlign: "center",
    left: "50%",
    bottom: "75%",
};
const LeftPane = ({ visibility, showModal, handleLogout, setUser }) => {
    const dispatch = useDispatch();
    const alertLogData = useSelector((state) => state.alertLogData);
    const [unreadCountOfAlerts, setUnreadCountOfAlerts] = useState(0);
    const modules = useSelector((state) => state.modules);
    const unreadChatCount = useSelector((state) => state.unreadChatCount);
    const [allModules, setAllModules] = useState([]);
    const { pathname } = useLocation();
    const [updatedPathName, setUpdatedPathName] = useState("");
    let basePathName = window.runtimeConfig.instanceName
        ? "/" + window.runtimeConfig.instanceName
        : "";

    // const [showWorkspaces, setShowWorkspaces] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        const path =
            pathname.charAt(pathname.length - 1) === "/"
                ? pathname.slice(0, -1)
                : pathname;
        setUpdatedPathName(path);
    }, [pathname]);
    useEffect(() => {
        dispatch(fetchModulesAction());
    }, []);
    useEffect(() => {
        if (alertLogData?.length > 0) {
            const unread = alertLogData.filter(
                (alert) => alert.isRead === false
            );
            setUnreadCountOfAlerts(unread.length);
        }
    }, [alertLogData]);
    useEffect(() => {
        if (modules && modules.length) {
            const modulesToShow = [
                {
                    source: "./images/UC-Icons-BW/workspace-icon-bw.svg",
                    title: "Workspaces",
                    pagename: "workspace",
                    path: "/workspace",
                    chck: "./images/UC-Icons-Color/workspace-logo-color.svg",
                    id: "div_leftPane_workspaceDropdown",
                },
            ];
            const userActiveModules = modules.filter(
                (module) => module.status === "ON"
            );
            const rippleModule = userActiveModules.find(
                (module) => module.moduleName === "Ripple"
            );
            const ripplelogModule = userActiveModules.find(
                (module) => module.moduleName === "Ripple Log"
            );
            const chatModule = userActiveModules.find(
                (module) => module.moduleName === "Communicator"
            );
            const archiveModule = userActiveModules.find(
                (module) => module.moduleName === "Archive"
            );
            const documentChainModule = userActiveModules.find(
                (module) => module.moduleName === "Document Chain"
            );
            const alertModule = userActiveModules.find(
                (module) => module.moduleName === "Alerts"
            );
            const trashbinModule = userActiveModules.find(
                (module) => module.moduleName === "Trash Bin"
            );
            const settingsModule = userActiveModules.find(
                (module) => module.moduleName === "Settings"
            );
            const directoryModule = userActiveModules.find(
                (module) => module.moduleName === "Directory"
            );
            const integrationsModule = userActiveModules.find(
                (module) => module.moduleName === "Integrations"
            );
            // modules.forEach((module) => {
            //     if (module.status.toUpperCase() === "OFF") return;
            //     if (module.moduleName === "Document Chain") {
            //         modulesToShow.push({});
            //     }
            //     modulesToShow.push(getModuleObject(module));
            // });
            if (rippleModule) modulesToShow.push(getModuleObject(rippleModule));
            if (ripplelogModule)
                modulesToShow.push(getModuleObject(ripplelogModule));
            if (documentChainModule)
                modulesToShow.push(
                    getModuleObject({
                        ...documentChainModule,
                        moduleName: "Flow",
                    })
                );
            if (alertModule)
                modulesToShow.push(
                    getModuleObject({
                        ...alertModule,
                        moduleName: "AlertLog",
                    })
                );
            if (chatModule)
                modulesToShow.push(
                    getModuleObject({ ...chatModule, moduleName: "Chat" })
                );
            if (directoryModule)
                modulesToShow.push(getModuleObject(directoryModule));
            if (integrationsModule)
                modulesToShow.push(
                    getModuleObject({
                        ...integrationsModule,
                        moduleName: "Apps",
                    })
                );
            if (archiveModule)
                modulesToShow.push(getModuleObject(archiveModule));
            if (trashbinModule)
                modulesToShow.push(getModuleObject(trashbinModule));
            if (settingsModule)
                modulesToShow.push(getModuleObject(settingsModule));
            setAllModules(modulesToShow);
        }
    }, [modules]);

    const getModuleObject = (module) => {
        const pathName = module.moduleName.split(" ").join("").toLowerCase();
        return {
            source: `./images/UC-Icons-BW/${pathName}-icon-bw.svg`,
            title:
                module.moduleName === "AlertLog" ? "Alerts" : module.moduleName,
            pagename: module.moduleName,
            path: `/${pathName}`,
            chck: `./images/UC-Icons-Color/${pathName}-logo-color.svg`,
            id: pathName,
        };
    };
    const getStaticModules = () => {
        return [
            {
                source: "./images/Group 443.svg",
                title: "UC",
                pagename: "HOME",
                path: "/",
                chck: "./images/Group 443.svg",
                id: "UC",
            },
            {
                source: "./images/UC-Icons-BW/home-icon-bw.svg",
                title: "Home",
                pagename: "HOME",
                path: "/",
                chck: "./images/UC-Icons-Color/home-logo-color.svg",
                id: "home",
            },
        ];
    };
    return (
        <>
            <div
                className="mainPageLeftPaneBG pl-0 pr-0 "
                style={{
                    height: "100vh",
                    background: "#27292C",
                    width: "100px",
                    // borderRight: "1px solid lightgray",
                }}
            >
                <div
                    style={{
                        height: "100%",
                        margin: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <div className="top-section">
                        <div className="top-section-static">
                            {getStaticModules().map((item, index) => (
                                <NavLink
                                    exact
                                    to={basePathName + item.path}
                                    key={item.id}
                                    style={{
                                        padding: index ? "8px" : "12px",
                                    }}
                                    activeClassName="mainPageLeftPaneListItem"
                                    onClick={() => {
                                        visibility(false);
                                        dispatch(
                                            setActivePageAction(item.pagename)
                                        );
                                    }}
                                    className={`${
                                        updatedPathName === basePathName &&
                                        index
                                            ? "ws-active"
                                            : ""
                                    }`}
                                >
                                    <div
                                        className={`left-pane-item ${
                                            updatedPathName === basePathName &&
                                            index
                                                ? "ws-active"
                                                : ""
                                        }`}
                                        id={"nav_" + item.title}
                                        title={item.title}
                                    >
                                        <img
                                            src={item.source}
                                            alt={item.title}
                                            style={{
                                                height: index ? "24px" : "32px",
                                                width: index ? "24px" : "32px",
                                            }}
                                            className={`${
                                                updatedPathName ===
                                                    basePathName && index
                                                    ? "left-pane-icon-active"
                                                    : ""
                                            }`}
                                        />
                                        {index ? (
                                            <span
                                                className="left-pane-item-title"
                                                style={{
                                                    color: `${
                                                        updatedPathName ===
                                                        basePathName
                                                            ? "black"
                                                            : "white"
                                                    }`,
                                                }}
                                            >
                                                {item.title}
                                            </span>
                                        ) : null}
                                    </div>
                                </NavLink>
                            ))}
                        </div>
                        <div
                            className="dynamic-tabs invisibleScroller"
                            id="invisibleScroller_nav"
                        >
                            <div className="top-section-dynamic">
                                {allModules.map((item, index) => {
                                    if (index === 0)
                                        return (
                                            <div
                                                className={`left-pane-item ${
                                                    updatedPathName ===
                                                    basePathName + item.path
                                                        ? "ws-active"
                                                        : ""
                                                }`}
                                                onClick={() =>
                                                    visibility(!showModal)
                                                }
                                                key={item.id}
                                                style={{
                                                    cursor: "pointer",
                                                    padding: "8px",
                                                }}
                                                title={item.title}
                                                id={"nav_" + item.title}
                                            >
                                                <img
                                                    src={item.source}
                                                    alt={item.title}
                                                    style={{
                                                        height: "24px",
                                                        width: "24px",
                                                    }}
                                                    className={`${
                                                        updatedPathName ===
                                                        basePathName + item.path
                                                            ? "left-pane-icon-active"
                                                            : ""
                                                    }`}
                                                />
                                                <span
                                                    className="left-pane-item-title"
                                                    style={{
                                                        color: `${
                                                            updatedPathName ===
                                                            basePathName +
                                                                item.path
                                                                ? "black"
                                                                : "white"
                                                        }`,
                                                    }}
                                                >
                                                    {item.title}
                                                </span>
                                            </div>
                                        );
                                    return (
                                        <NavLink
                                            exact
                                            activeClassName="mainPageLeftPaneListItem"
                                            to={basePathName + item.path}
                                            key={item.id}
                                            style={{ padding: "8px" }}
                                            onClick={() => {
                                                item.id === "workspace"
                                                    ? visibility(!showModal)
                                                    : visibility(false);
                                                dispatch(
                                                    setActivePageAction(
                                                        item.pagename === "Flow"
                                                            ? "DocuChain"
                                                            : item.pagename
                                                    )
                                                );
                                            }}
                                            className={`${
                                                updatedPathName ===
                                                basePathName + item.path
                                                    ? "ws-active"
                                                    : ""
                                            }`}
                                        >
                                            <div
                                                className={`left-pane-item ${
                                                    updatedPathName ===
                                                    basePathName + item.path
                                                        ? "ws-active"
                                                        : ""
                                                }`}
                                                id={"nav_" + item.title}
                                                title={item.title}
                                                style={{
                                                    position:
                                                        item.title ===
                                                            "Alerts" ||
                                                        item.title === "Chat"
                                                            ? "relative"
                                                            : null,
                                                }}
                                            >
                                                <img
                                                    src={item.source}
                                                    alt={item.title}
                                                    style={{
                                                        height: "24px",
                                                        width: "24px",
                                                    }}
                                                    className={`${
                                                        updatedPathName ===
                                                        basePathName + item.path
                                                            ? "left-pane-icon-active"
                                                            : ""
                                                    }`}
                                                />
                                                {item.title === "Alerts" &&
                                                unreadCountOfAlerts &&
                                                unreadCountOfAlerts > 0 ? (
                                                    <span
                                                        style={alertNotifyStyle}
                                                        className="textEllipsis"
                                                    >
                                                        {unreadCountOfAlerts}
                                                    </span>
                                                ) : null}
                                                {item.title === "Chat" &&
                                                unreadChatCount !== undefined &&
                                                unreadChatCount !== 0 ? (
                                                    <span
                                                        style={alertNotifyStyle}
                                                        className="textEllipsis"
                                                    >
                                                        {unreadChatCount}
                                                    </span>
                                                ) : null}

                                                <span
                                                    className="left-pane-item-title"
                                                    style={{
                                                        color: `${
                                                            updatedPathName ===
                                                            basePathName +
                                                                item.path
                                                                ? "black"
                                                                : "white"
                                                        }`,
                                                    }}
                                                >
                                                    {item.title}
                                                </span>
                                            </div>
                                        </NavLink>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="bottom-section">
                        <UserSection
                            visibility={visibility}
                            handleLogout={handleLogout}
                            setUser={setUser}
                            hideWSList={visibility}
                        ></UserSection>
                    </div>
                </div>
                <Loader show={showLoader} />
            </div>

            {/* {showModal ? ( */}
            <WorkspaceList
                showModal={showModal}
                setShowModal={visibility}
            ></WorkspaceList>
            {/* ) : null} */}
        </>
    );
};

export default LeftPane;
