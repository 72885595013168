import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import {
    setActivePage,
    setActiveRipple,
    setChatRippleMsg,
} from "../../../../../../../../actions";
import {
    openInstance,
    setExistingGenericData,
    fetchInstanceDataByGenericId,
} from "../../../../../../../../actions/DashboardActions";
import { setChipArray } from "../../../../../../../../actions/Rippleaction";
import http from "../../../../../../../../bae-client/HTTP";
import { getChatTimestamp } from "../../../../../../../../bae-client/Utils";
import { executeClickEvents } from "../../../../../../../helper/EventHelper";
import AlertDialog from "../../../../../../../stylized/AlertDialog";
import DialogView from "../../../../../../../stylized/DialogView";
import DocViwer from "../../../../../../../stylized/DocViwer";
import Loader from "../../../../../../../stylized/Loader";
import DocumentViewerModal from "../../../../../../../stylized/DocumentViewerModal";
import { getIconPathByType } from "../../../../../../common/IconList";
import FormWrapper from "../../../../Integration/FormWrapper/FormWrapper";
import "./ChatMessage.css";
import { fetchWorkspaceListAction } from "../../../../../../../../services/services/app/";
import { fetchWorkspaceListData } from "../../../../../../../../actions/WokspaceActions";
import { fetchHierarchyResponse } from "../../../../../../../../actions/HierarchyActions";
function ChatMessages({ messageDetails, setChipArray, chipsArray }) {
    const [showUploadOption, setShowUploadOption] = React.useState(false);
    const [documentModalShow, setDocumentModalShow] = useState(false);
    const [currentShowFileName, setCurrentShowFileName] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [currentShowFileLocation, setCurrentShowFileLocation] = useState("");
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const dispatch = useDispatch();
    const userIdToFullName = useSelector((state) => state.userIdToFullName);
    const genericNameToIdMap = useSelector((state) => state.genericNameToIdMap);
    const genericIdToNameMap = useSelector((state) => state.genericIdToNameMap);
    const [displayShow, setDisplayShow] = useState(false);
    const [showLoder, setShowLoder] = useState(false);
    const instanceDataByGenericId = useSelector(
        (state) => state.instanceDataByGenericId
    );
    const style = {
        display: "block",
        margin: "5px 0",
    };
    const isMe =
        messageDetails.messageBy === parseInt(sessionStorage.getItem("userId"));
    const floatDirection = isMe ? "right" : "left";
    const flexDirection = isMe ? "end" : "start";
    const margin = isMe ? " 0 0 0 40px" : "0 40px 0 0 ";

    const textStyle = {
        float: floatDirection,
        backgroundColor: isMe ? "#0798EA" : "#F3F3F3",
        padding: "6px 10px",
        borderRadius: isMe ? "15px 15px 0px 15px" : "15px 15px 15px 0px",
        margin: margin,
        textAlign: "left",
        marginTop: "15px",
        maxWidth: "30rem",
    };

    const nameStyle = {
        color: isMe ? "#FFFFFF" : "#000000",
        float: floatDirection,
        fontSize: "12px",
        lineHeight: "26px",
    };

    const userNameStyle = {
        color: "#000000",
        float: floatDirection,
        fontSize: "12px",
        lineHeight: "26px",
    };
    const divStyle = {
        display: "flex",
        justifyContent: flexDirection,
        width: "100%",
    };

    const showModal = (event, fileName, fileLocation, isDownload) => {
        setDocumentModalShow(true);
        setCurrentShowFileName(fileName);
        setIsChecked(isDownload);
        setCurrentShowFileLocation(fileLocation);
    };
    const renderViewDocumentModal = (event) => {
        return (
            <DocViwer
                docName={currentShowFileName}
                fileLocation={currentShowFileLocation}
            ></DocViwer>
        );
    };
    const handleDocumentModalClose = () => {
        setDocumentModalShow(false);
        // setCurrentShowFileLocation("");
    };
    const showModalView = (event, fileName, documentId, isDownload) => {
        event.stopPropagation();
        setShowLoder(true);
        http.sendRestRequest(`document/documentDetail?documentId=${documentId}`)
            .then((response) => {
                // Attempt to access documentName from multiple potential locations
                const documentName =
                    response.documentName || response.data?.documentName || "";

                if (documentName) {
                    showModal(event, documentName, response, isDownload);
                    setShowLoder(false);
                } else {
                    throw new Error("Document name not found in response");
                    setShowLoder(false);
                }
            })
            .catch((error) => {
                setShowAlertDialog(true);
                setAlertDialogMessage(
                    error.message ||
                        "An error occurred while fetching the document details."
                );
                setShowLoder(false);
                console.log(error);
            });
    };

    const fetchInstanceDataByGenericId = (id) => {
        return new Promise((resolve, reject) => {
            http.sendRestRequest("documentChain/instance/list?genericId=" + id)
                .then((resp) => {
                    let instanceMap = new Map();
                    if (resp && resp.length > 0) {
                        resp.forEach((instanceItem) => {
                            instanceMap.set(
                                instanceItem.instanceName.toLowerCase(),
                                instanceItem.instanceId
                            );
                        });
                    }
                    resolve(instanceMap);
                })
                .catch((err) => {
                    setShowAlertDialog(true);
                    setAlertDialogMessage(err.error);
                    reject(err);
                });
        });
    };
    const redirectToDocumentChain = (e) => {
        if (messageDetails.messageType === "MSG_FLOW") {
            const sharedObjectDetailArray = Object.values(
                JSON.parse(messageDetails.message.split("|")[1])
            );
            let genericId = genericNameToIdMap.get(
                sharedObjectDetailArray[1].toLowerCase()
            );
            if (genericId) {
                setShowAlertDialog(true);
                setAlertDialogMessage(
                    `${sharedObjectDetailArray[1]} is already existing generic `
                );
            } else {
                setShowLoder(true);
                http.sendRestRequest(
                    `share/saveGeneric?ownerUserId=${messageDetails.messageBy}&ownerGenericId=${sharedObjectDetailArray[0]}`,
                    "POST"
                )
                    .then((response) => {
                        setShowLoder(false);
                        dispatch(fetchWorkspaceListData());
                        setShowAlertDialog(true);
                        setAlertDialogMessage(
                            `Flow ${sharedObjectDetailArray[1]} has been saved successfully!`
                        );
                    })
                    .catch((error) => {
                        setShowLoder(false);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(error.error);
                    });
            }
        } else {
            let data = JSON.parse(messageDetails.message.split("|")[1]);
            let genericName = data.genericName;
            let genericId = genericNameToIdMap.get(genericName.toLowerCase());
            if (genericName && genericId) {
                setShowLoder(true);
                fetchInstanceDataByGenericId(genericId)
                    .then((instanceMap) => {
                        const instanceId = instanceMap.get(
                            data.instanceName.toLowerCase()
                        );
                        setShowLoder(false);
                        if (instanceId) {
                            const libData = {
                                genericId: genericId,
                                genericName: genericName,
                                instanceIds: [instanceId],
                            };
                            dispatch(setActivePage("DocuChain"));
                            dispatch(
                                setExistingGenericData(messageDetails.message)
                            );
                            dispatch(openInstance(libData));
                        } else {
                            setShowLoder(false);
                            setShowAlertDialog(true);
                            setAlertDialogMessage(
                                "Instance not present at your place.Please contact to admin"
                            );
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(err.error);
                        setShowLoder(false);
                    });
            } else {
                setShowLoder(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(
                    "Generic not present at your place.Please contact to admin"
                );
                // alert("Generic not present at your place.Please contact to admin");
            }
        }
    };
    const handleClick = (name, id, row) => {
        if (messageDetails.messageType === "MSG_FILE_OBJECT") {
            return (event) => {
                if (
                    messageDetails.message.split("|")[1].split(":").length === 3
                ) {
                    const sharedObject = messageDetails
                        .split("|")[1]
                        .split(":");
                    let fileName = sharedObject[1];
                    let documentId = sharedObject[0];

                    showModalView(event, fileName, documentId, null);
                } else {
                    const sharedObject = JSON.parse(
                        messageDetails.message.split("|")[1]
                    );
                    let fileName = sharedObject.folderFileName;
                    let documentId = sharedObject.documentId;
                    let isDownload = sharedObject.isDownload;

                    showModalView(event, fileName, documentId, isDownload);
                }
            };
        } else {
            return (event) => {
                const fileDetailArray = messageDetails.message.split(":");
                // Validate array length
                if (fileDetailArray.length === 4) {
                    const documentId = fileDetailArray[0];
                    const fileName = fileDetailArray[1];
                    let isDownload = fileDetailArray[3];
                    showModalView(event, fileName, documentId, isDownload);
                } else {
                    console.error(
                        "Invalid message format:",
                        messageDetails.message
                    );
                }
            };
        }
    };

    const handleDoubleClick = (name, id, row) => {
        if (isMe) return () => {};
        return (event) => {
            setShowUploadOption(true);
        };
    };

    const handleCloseUploadDialog = () => {
        setShowUploadOption(false);
    };

    const handleShareAttachment = (shareObject) => {
        if (messageDetails.messageType === "FILE_OBJECT") {
            const fileDetailArray = messageDetails.message.split(":");
            const messageFileData = {
                objectId: parseInt(fileDetailArray[0]),
                objectName: fileDetailArray[1],
                isEditable: fileDetailArray[2],
                isDownload: fileDetailArray[3],
            };
            const storeItemPayload = {
                objectId: messageFileData.objectId,
                objectName: messageFileData.objectName,
                parentRowId: parseInt(shareObject.folderFileId),
                spaceId: parseInt(shareObject.spaceId),
                isEditable:
                    messageFileData.isEditable === "true" ||
                    messageFileData.isEditable === true
                        ? true
                        : false,
            };

            http.sendRestRequest(
                `share/saveFile`, // Correct URL format
                "POST",
                {
                    ...storeItemPayload,
                    allowDownload: messageFileData.isDownload,
                }
            )
                .then((response) => {
                    setShowAlertDialog(true);
                    setAlertDialogMessage(
                        `File has been added to ${
                            shareObject.folderFileName || shareObject.spaceName
                        } successfully!`
                    );
                })
                .catch((error) => {
                    setShowAlertDialog(true);
                    setAlertDialogMessage(
                        error.message ||
                            "An error occurred while saving the file."
                    );
                });
        } else {
            const sharedItems = messageDetails.message.split("|");

            let messageFileData = JSON.parse(sharedItems[1]);

            if (messageDetails.messageType === "MSG_FILE_OBJECT") {
                const fileDetailObject = JSON.parse(sharedItems[1]);

                const storeItemPayload = {
                    objectId: parseInt(fileDetailObject.documentId),
                    objectName: fileDetailObject.folderFileName,
                    parentRowId: parseInt(shareObject.folderFileId),
                    spaceId: parseInt(shareObject.spaceId),
                    isEditable: messageFileData.hasOwnProperty("isEditable")
                        ? messageFileData.isEditable
                        : false,
                    allowDownload: messageFileData.isDownload,
                };
                setShowLoder(true);

                http.sendRestRequest(`share/saveFile`, "POST", {
                    ...storeItemPayload,
                    allowDownload:
                        messageFileData.isDownload === "true" ||
                        messageFileData.isDownload === true
                            ? true
                            : false,
                })
                    .then((response) => {
                        setShowAlertDialog(true);
                        setShowLoder(false);
                        setAlertDialogMessage(
                            `File has been added to ${
                                shareObject.folderFileName ||
                                shareObject.spaceName
                            } successfully!`
                        );
                    })
                    .catch((error) => {
                        setShowLoder(false);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(error.error);
                    });
            }
            if (
                messageDetails.messageType === "MSG_FOLDER_OBJECT" ||
                messageDetails.messageType === "FOLDER_OBJECT"
            ) {
                const sharedObjectDetailArray = Object.values(
                    JSON.parse(sharedItems[1])
                );
                setShowLoder(true);
                http.sendRestRequest(
                    `share/saveFolder?ownerUserId=${
                        messageDetails.messageBy
                    }&ownerFolderId=${
                        sharedObjectDetailArray[0]
                    }&workspaceId=${parseInt(
                        shareObject.workspaceId
                    )}&spaceId=${shareObject.spaceId}&parentFolderId=${
                        shareObject.folderFileId
                    }&shareUpdates=${
                        sharedObjectDetailArray[2]
                    }&receiveUpdates=${
                        sharedObjectDetailArray[3]
                    }&allowDownload=${sharedObjectDetailArray[1]}`,
                    "POST"
                    // JSON.parse(
                    //     messageDetails.messageType === "FOLDER_OBJECT"
                    //         ? messageDetails.message
                    //         : sharedItems[1]
                    // )
                )
                    .then((response) => {
                        setShowLoder(false);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(
                            `Folder has been added to ${shareObject.spaceName} successfully!`
                        );
                    })
                    .catch((error) => {
                        setShowLoder(false);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(error.error);
                    });
            }
            if (
                messageDetails.messageType === "MSG_SPACE_OBJECT" ||
                messageDetails.messageType === "SPACE_OBJECT"
            ) {
                const sharedObjectDetailArray = Object.values(
                    JSON.parse(sharedItems[1])
                );
                setShowLoder(true);
                http.sendRestRequest(
                    `share/saveSpace?ownerUserId=${messageDetails.messageBy}&ownerSpaceId=${sharedObjectDetailArray[0]}&workspaceId=${shareObject.workspaceId}&shareUpdates=${sharedObjectDetailArray[2]}&receiveUpdates=${sharedObjectDetailArray[3]}&allowDownload=${sharedObjectDetailArray[1]}`,
                    "POST"
                    // JSON.parse(
                    //     messageDetails.messageType === "SPACE_OBJECT"
                    //         ? messageDetails.message
                    //         : sharedItems[1]
                    // )
                )
                    .then((response) => {
                        setShowLoder(false);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(
                            `Space has been added to ${shareObject.workspaceName} successfully!`
                        );
                    })
                    .catch((error) => {
                        setShowLoder(false);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(error.error);
                    });
            }
            if (messageDetails.messageType === "MSG_WORKSAPCE_OBJECT") {
                const sharedObjectDetailArray = Object.values(
                    JSON.parse(sharedItems[1])
                );
                setShowLoder(true);
                http.sendRestRequest(
                    `share/saveSpace?ownerUserId=${messageDetails.messageBy}&ownerSpaceId=${sharedObjectDetailArray[0]}&workspaceId=${shareObject.workspaceId}&allowDownload=${sharedObjectDetailArray[1]}`,
                    "POST"
                    // JSON.parse(
                    //     messageDetails.messageType === "SPACE_OBJECT"
                    //         ? messageDetails.message
                    //         : sharedItems[1]
                    // )
                )
                    .then((response) => {
                        setShowLoder(false);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(
                            `Space has been added to ${shareObject.workspaceName} successfully!`
                        );
                    })
                    .catch((error) => {
                        setShowLoder(false);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(error.error);
                    });
            }
            if (
                messageDetails.messageType === "MSG_RIPPLE_OBJECT" ||
                messageDetails.messageType === "RIPPLE_OBJECT"
            ) {
                //
                const rippleData = JSON.parse(
                    messageDetails.messageType === "RIPPLE_OBJECT"
                        ? messageDetails.message
                        : sharedItems[1]
                );
                if (chipsArray.length !== 0) {
                    setChipArray([]);
                }
                dispatch(setChatRippleMsg("chat"));
                dispatch(setActiveRipple(rippleData));
                dispatch(setActivePage("Ripple"));
            }
        }
    };
    const changeOpenDialogFlag = (value) => {
        setShowAlertDialog(value);
        setAlertDialogMessage("");
    };
    const getMessage = () => {
        if (messageDetails.messageType === "FILE_OBJECT") {
            return messageDetails.message.split(":")[1];
        }
        if (messageDetails.messageType === "MSG_FILE_OBJECT") {
            // return messageDetails.message.split("|")[1].split(":")[1];
            if (messageDetails.message.split("|")[1].split(":").length === 2) {
                const sharedObject = messageDetails.message
                    .split("|")[1]
                    .split(":");
                let fileName = sharedObject[1];
                let documentId = sharedObject[0];
                return fileName;
            } else {
                const sharedObject = JSON.parse(
                    messageDetails.message.split("|")[1]
                );
                return sharedObject.folderFileName;
            }
        }
        if (
            messageDetails.messageType === "FOLDER_OBJECT" ||
            messageDetails.messageType === "SPACE_OBJECT"
        ) {
            const sharedObject = JSON.parse(messageDetails.message);
            return Object.keys(sharedObject)[0];
        }
        if (messageDetails.messageType === "RIPPLE_OBJECT") {
            const sharedObject = JSON.parse(messageDetails.message);
            return sharedObject.rippleName || "Unsaved Ripple";
        }
        if (messageDetails.messageType === "MSG_RIPPLE_OBJECT") {
            const sharedObject = JSON.parse(
                messageDetails.message.split("|")[1]
            );
            return sharedObject.rippleName || "Unsaved Ripple";
        }
        if (messageDetails.messageType === "MSG_INSTANCE_STEP") {
            const sharedObject = JSON.parse(
                messageDetails.message.split("|")[1]
            );
            if (sharedObject.shareType === "step") {
                return sharedObject.genericStepName || "Unsaved Step";
            } else {
                return sharedObject.instanceName || "Unsaved Insatnce";
            }
            // return sharedObject.genericStepName || "Unsaved Ripple";
        }
        if (messageDetails.messageType === "MSG_FLOW") {
            const sharedObject = JSON.parse(
                messageDetails.message.split("|")[1]
            );
            return sharedObject.genericDisplayName;
            // return sharedObject.genericStepName || "Unsaved Ripple";
        }
        if (
            messageDetails.messageType === "MSG_FOLDER_OBJECT" ||
            messageDetails.messageType === "MSG_SPACE_OBJECT"
        ) {
            const sharedObject = JSON.parse(
                messageDetails.message.split("|")[1]
            );
            return Object.keys(sharedObject)[0];
        }
        if (messageDetails.messageType === "MSG_WORKSPACE_OBJECT") {
            const arr = messageDetails.message.split("|");
            let sharedObject = null;
            if (arr.length > 1) {
                sharedObject = JSON.parse(arr[1]);
            } else sharedObject = JSON.parse(arr[0]);
            return sharedObject.workspaceName;
        }
    };
    const renderIcon = (type) => {
        switch (type) {
            case "FILE_OBJECT":
                return getIconPathByType("file_small");
            case "MSG_FILE_OBJECT":
                return getIconPathByType("file_small");
            case "FOLDER_OBJECT":
                return getIconPathByType("folder_small");
            case "MSG_FOLDER_OBJECT":
                return getIconPathByType("folder_small");
            case "SPACE_OBJECT":
                return getIconPathByType("space_small");
            case "MSG_SPACE_OBJECT":
                return getIconPathByType("space_small");
            case "MSG_WORKSPACE_OBJECT":
                return getIconPathByType("workspace_small");
            case "RIPPLE_OBJECT":
                return getIconPathByType("ripple_small");
            case "MSG_RIPPLE_OBJECT":
                return getIconPathByType("ripple_small");
            case "MSG_INSTANCE_STEP":
                return getIconPathByType("documentChain");
            case "MSG_FLOW":
                return getIconPathByType("documentChain");
            default:
                return null;
        }
    };
    const saveSharedWorkspace = (details) => {
        if (isMe) return;
        const { messageBy, message } = details;
        const messageArray = message.split("|");

        const workspace = JSON.parse(
            messageArray.length > 1 ? messageArray[1] : message
        );
        if (messageBy && workspace && workspace.workspaceId) {
            http.sendRestRequest(
                `share/saveWorkspace?ownerUserId=${messageBy}&ownerWorkspaceId=${workspace.workspaceId}&shareUpdates=${workspace.shareUpdates}&receiveUpdates=${workspace.receiveUpdates}&allowDownload=${workspace.isDownload}&`,
                "POST"
            )
                .then((response) => {
                    setShowAlertDialog(true);
                    setAlertDialogMessage(
                        "Shared workspace saved successfully!"
                    );
                    dispatch(fetchWorkspaceListData());
                    dispatch(fetchWorkspaceListAction());
                })
                .catch((error) => {
                    setShowAlertDialog(true);
                    setAlertDialogMessage(error.error);
                });
        }
    };
    return (
        <div style={style} id={`userChatMessage`}>
            <span style={textStyle}>
                <span
                    className="mr-2"
                    id="spn_ChatMessages_userName_from"
                    style={{ ...nameStyle, fontWeight: "bold" }}
                >
                    {userIdToFullName !== null && !isMe
                        ? userIdToFullName.get(messageDetails.messageBy)
                        : null}
                </span>
                <span style={nameStyle}>
                    {getChatTimestamp(
                        messageDetails.messageOn,
                        "mm/dd/yyyy hh:mm aa"
                    )}
                </span>
                <br />
                <div
                    className="text_msg_body"
                    style={{
                        color: isMe
                            ? messageDetails.messageType === "MESSAGE"
                                ? "#FFFFFF"
                                : "#000000"
                            : "#000000",

                        border: !(messageDetails.messageType === "MESSAGE")
                            ? "1px solid"
                            : "none",
                        borderRadius: !(
                            messageDetails.messageType === "MESSAGE"
                        )
                            ? "10px"
                            : "0",
                        backgroundColor: isMe
                            ? messageDetails.messageType === "MESSAGE"
                                ? ""
                                : "#FFFFFF"
                            : "",
                    }}
                >
                    {messageDetails.messageType === "FILE_OBJECT" ||
                    messageDetails.messageType === "FOLDER_OBJECT" ||
                    messageDetails.messageType === "SPACE_OBJECT" ||
                    messageDetails.messageType === "RIPPLE_OBJECT" ? (
                        <div
                            className="row w-100 m-0"
                            style={{
                                padding: "5px",
                                cursor:
                                    messageDetails.messageType !== "MESSAGE"
                                        ? "pointer"
                                        : "default",
                                display: "flex",
                                alignItems: "center",
                                flexFlow: "row",
                            }}
                            onClick={(event) => {
                                messageDetails.messageType ===
                                    "MSG_RIPPLE_OBJECT" ||
                                messageDetails.messageType === "RIPPLE_OBJECT"
                                    ? handleShareAttachment()
                                    : messageDetails.messageType ===
                                          "FILE_OBJECT" ||
                                      messageDetails.messageType ===
                                          "MSG_FILE_OBJECT"
                                    ? executeClickEvents(
                                          event,
                                          handleClick(
                                              messageDetails.message,
                                              messageDetails.id,
                                              messageDetails
                                          ),
                                          handleDoubleClick(
                                              messageDetails.message,
                                              messageDetails.id,
                                              messageDetails
                                          )
                                      )
                                    : messageDetails.messageType ===
                                      "MSG_WORKSPACE_OBJECT"
                                    ? alert("workspace shared")
                                    : !isMe && setShowUploadOption(true);
                            }}
                        >
                            <div
                                id="msg_text_icon"
                                className="doCenter"
                                style={{ width: "auto" }}
                            >
                                <img
                                    src={renderIcon(messageDetails.messageType)}
                                    alt=""
                                    className="chat_Content_icon"
                                />
                            </div>
                            <div
                                className="ml-2 textEllipsis"
                                title={getMessage()}
                                style={{ width: "auto" }}
                            >
                                <span id="msg_text">{getMessage()}</span>
                            </div>
                        </div>
                    ) : messageDetails.messageType === "MSG_FILE_OBJECT" ||
                      messageDetails.messageType === "MSG_FOLDER_OBJECT" ||
                      messageDetails.messageType === "MSG_WORKSPACE_OBJECT" ||
                      messageDetails.messageType === "MSG_SPACE_OBJECT" ||
                      messageDetails.messageType === "MSG_RIPPLE_OBJECT" ||
                      messageDetails.messageType === "MSG_INSTANCE_STEP" ||
                      messageDetails.messageType === "MSG_FLOW" ? (
                        <div
                            className="row w-100 m-0"
                            style={{
                                padding: "5px",
                                cursor:
                                    messageDetails.messageType !== "MESSAGE"
                                        ? "pointer"
                                        : "default",
                                display: "flex",
                                alignItems: "center",
                                flexFlow: "row",
                                border: "1px solid black 10px",
                            }}
                            onClick={(event) => {
                                messageDetails.messageType ===
                                "MSG_RIPPLE_OBJECT"
                                    ? handleShareAttachment()
                                    : messageDetails.messageType ===
                                      "MSG_FILE_OBJECT"
                                    ? executeClickEvents(
                                          event,
                                          handleClick(
                                              messageDetails.message,
                                              messageDetails.id,
                                              messageDetails
                                          ),
                                          handleDoubleClick(
                                              messageDetails.message,
                                              messageDetails.id,
                                              messageDetails
                                          )
                                      )
                                    : messageDetails.messageType ===
                                          "MSG_INSTANCE_STEP" ||
                                      messageDetails.messageType === "MSG_FLOW"
                                    ? redirectToDocumentChain()
                                    : messageDetails.messageType ===
                                      "MSG_WORKSPACE_OBJECT"
                                    ? saveSharedWorkspace(messageDetails)
                                    : !isMe && setShowUploadOption(true);
                            }}
                        >
                            <div
                                id="msg_text_icon"
                                className="doCenter"
                                style={{ width: "auto" }}
                            >
                                <img
                                    src={renderIcon(messageDetails.messageType)}
                                    alt=""
                                    className="chat_Content_icon"
                                />
                            </div>
                            <div
                                className="ml-2 textEllipsis"
                                title={getMessage()}
                                style={{ width: "auto" }}
                            >
                                <span id="msg_text">{getMessage()}</span>
                            </div>
                        </div>
                    ) : (
                        messageDetails.message
                    )}
                </div>
                {messageDetails.messageType !== "MESSAGE" &&
                messageDetails.messageType !== "FILE_OBJECT" &&
                messageDetails.messageType !== "FOLDER_OBJECT" &&
                messageDetails.messageType !== "SPACE_OBJECT" &&
                messageDetails.messageType !== "RIPPLE_OBJECT" &&
                messageDetails.messageType !== "MSG_WORKSPACE_OBJECT" ? (
                    <div
                        className="text_msg_body"
                        style={{
                            color: isMe ? "white" : "black",
                            float: "none",
                        }}
                    >
                        {messageDetails.message.split("|")[0]}
                    </div>
                ) : null}
            </span>
            <DialogView
                show={showUploadOption}
                size="lg"
                handleClose={handleCloseUploadDialog}
                showTitle={false}
                showFooter={false}
                onBackdropClick={false}
                style={displayShow ? { display: "none" } : { display: "block" }}
            >
                <FormWrapper
                    onHide={handleCloseUploadDialog}
                    accountType={"headerTitle"}
                    Display={setDisplayShow}
                    defaultStep={1}
                    handleShareAttachment={handleShareAttachment}
                    heading={`Save ${messageDetails.messageType.split("_")[1]}`}
                    hideSpace={
                        messageDetails.messageType === "MSG_SPACE_OBJECT"
                    }
                    hideFolder={
                        messageDetails.messageType === "MSG_SPACE_OBJECT"
                    }
                    hideFile={true}
                    saveButtonName={"Save"}
                ></FormWrapper>
            </DialogView>
            <DocumentViewerModal
                showHeader={true}
                footer="false"
                show={documentModalShow}
                size="lg"
                onHide={handleDocumentModalClose}
                title=""
                modalBodyClass="documentViewModal"
                onclick={() => {}}
                submitButtonText="Close"
                headerTitle={currentShowFileName}
                isChecked={isChecked}
                fileSource={currentShowFileLocation}
            >
                {documentModalShow ? renderViewDocumentModal() : null}
            </DocumentViewerModal>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
                setOpen={changeOpenDialogFlag}
            ></AlertDialog>
            <Loader show={showLoder}></Loader>
        </div>
    );
}

const mapDispatchToProps = () => {
    return {
        setChipArray,
    };
};

const mapStateToProps = (state) => {
    return {
        chipsArray: state.chipsArray,
    };
};
export default connect(mapStateToProps, mapDispatchToProps())(ChatMessages);
